import React, { useState } from "react";
import { Button } from "antd";
import { FaTiktok } from "react-icons/fa";

import { useDispatch } from "react-redux";
import tiktok from "../../Utils/Images/tiktok.png";
import { TikTokLoginHandler } from "../../redux/auth/authSlice";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";

const TikTokLogin = () => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    const userData = {
      email: GetDecryptEmailformat(),
      reference_number: GetDecryptReferenceNumberformat(),
      client_type: "mobile",
    };

    dispatch(TikTokLoginHandler({ userData }));
  };

  return (
    <div className="tiktok_css">
      <div style={{ textAlign: "center", margin: "50px" }}>
        <header className="qr-header">
          <img src={tiktok} alt="Tiktok Logo" className="tiktoklogo" />
          <h1>Continue with TikTok</h1>
        </header>

        <p>Click the button below to Continue with your TikTok account:</p>
        <Button className="tiktok" type="primary" onClick={handleLogin} size="large" shape="round">
          <FaTiktok style={{ marginRight: "8px" }} />
          Continue with TikTok
        </Button>
      </div>
    </div>
  );
};

export default TikTokLogin;
