import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import {
  DecryptedDataAdmin,
  AdminDashboardGraph,
  EncryptDataAdmin,
  GetDecryptAccessTokenformat,
  GetDecryptEmailformat,
  GetDecryptReferenceNumberformat,
  SetEncryptAccessTokenformat,
  SetEncryptEmailformat,
  SetEncryptPasswordformat,
  SetEncryptReferenceNumberformat,
  SetEncryptUserNameformat,
  SetUserData,
  GetDecryptUniqId,
} from "../../Components/Common/Common";

const initialState = {
  email: "",
  googleSigninData: [],
  profileDetails: [],
  isLoading: false,
  isInsiteDataLoading: true,
  wordsData: [],
  InstagramUserDetails: "",
  InstagramProfileData: "",
  ImageCaptionGenerate: "",
  TokenID: "",
  HashedTokenID: "",
  S3BucketImage: "",
  getUserWalletDetails: "",
  walletBalanceUser: "",
  userPrivateKey: "",
  transactionHistory: [],
  AiAgentResponse: [],
  BillingCostOpenAI: {},
  BillingCostOpenAIAmount: [],
  hasInstaAccess: false,
  ServerDailyCost: {},
  StabilityDiffusion: {},
  userlimitdata: "",
  CostGraph: {},
  userRole: null,
  BillingCostGemini: "",
  profileDetailsAdmin: "",
  AllUserDetails: [],
  encyptData: "",
  UsageLLMUser: [],
  AwsCostUsage: {},
  ALLfaq: [],
  QrcodeDetails: {},
  GetQrcodeDetails: {},
  getuseruniqueId: "",
};

const location = window.location;

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    HandleEmaildata: (state, actions) => {
      state.email = actions?.payload;
    },
    HandleGoogleSigninData: (state, actions) => {
      state.googleSigninData = actions?.payload;
    },
    HandleProfileDetails: (state, actions) => {
      state.profileDetails = actions?.payload;
    },
    HandleProfileDetailsAdmin: (state, actions) => {
      state.profileDetailsAdmin = actions?.payload;
    },
    HandleQrCode: (state, actions) => {
      state.QrcodeDetails = actions?.payload;
    },
    GetHandleQrCode: (state, actions) => {
      state.GetQrcodeDetails = actions?.payload;
    },
    SetLoading: (state, actions) => {
      state.isLoading = actions?.payload;
    },
    SetDataurl: (state, actions) => {
      state.Dataredirect = actions?.payload;
    },
    HandleAllFaq: (state, actions) => {
      state.ALLfaq = actions?.payload;
    },
    SetBillingCostOpenAI: (state, actions) => {
      state.BillingCostOpenAI = actions?.payload;
    },
    UpdateApiStatus: (state, actions) => {
      state.ApiStatus = actions?.payload;
    },
    SetBillingCostGemini: (state, actions) => {
      state.BillingCostGemini = actions?.payload;
    },
    HandleSetBillingCostOpenAI: (state, actions) => {
      state.BillingCostOpenAIAmount = actions?.payload;
    },
    HandleMaildata: (state, actions) => {
      state.wordsData = actions?.payload;
    },
    HandleAiagentdata: (state, actions) => {
      state.AiAgentResponse = actions?.payload;
    },
    HandleWalletDetails: (state, actions) => {
      state.getUserWalletDetails = actions?.payload;
    },
    HandleUserPrivatekeyDetails: (state, actions) => {
      state.userPrivateKey = actions?.payload;
    },
    HandleWalletBalance: (state, actions) => {
      state.walletBalanceUser = actions?.payload;
    },

    HandleInstagramUserData: (state, actions) => {
      state.InstagramUserDetails = actions?.payload;
    },

    HandleUpdateHasInstaAccess: (state, actions) => {
      state.hasInstaAccess = actions?.payload;
    },
    HandleUpdateUserRole: (state, actions) => {
      state.userRole = actions?.payload;
    },

    HandleTransactionHistory: (state, actions) => {
      state.transactionHistory = actions?.payload;
    },

    HandleInstagramUserProfileData: (state, actions) => {
      state.InstagramProfileData = actions?.payload;
    },
    HandleImageCaptionGenerate: (state, actions) => {
      state.ImageCaptionGenerate = actions?.payload;
    },

    HandleTokenIdGenerate: (state, actions) => {
      state.TokenID = actions?.payload;
    },
    HandleHashedTokenId: (state, actions) => {
      state.HashedTokenID = actions?.payload;
    },
    HandleS3BucketImage: (state, actions) => {
      state.S3BucketImage = actions?.payload;
    },
    SetInsiteLoading: (state, actions) => {
      state.isInsiteDataLoading = actions?.payload;
    },
    HandleServerDailyCost: (state, action) => {
      state.ServerDailyCost = action?.payload;
    },
    HandleStabilityDiffusionCost: (state, action) => {
      state.StabilityDiffusion = action?.payload;
    },
    HandleUserlimit: (state, action) => {
      state.userlimitdata = action?.payload;
    },
    HandlegetServerCostGraph: (state, action) => {
      state.CostGraph = action?.payload;
    },
    HandleGetOpenAiLimit: (state, action) => {
      state.OpenAiLimit = action?.payload;
    },
    HandlegetOpenAiCostGraph: (state, action) => {
      state.CostGraphOpenAi = action?.payload;
    },
    HandleGetUserStabilityDiffusionLimitSet: (state, action) => {
      state.StabilityLimit = action?.payload;
    },
    HandlegetStabilityCostGraph: (state, action) => {
      state.CostGraphStability = action?.payload;
    },
    HandleGeminiLimit: (state, action) => {
      state.GeminiLimit = action?.payload;
    },
    HandlegetGeminiCostGraph: (state, action) => {
      state.CostGraphGemini = action?.payload;
    },
    HandleAwsDetailsData: (state, action) => {
      state.AwsCostUsage = action?.payload;
    },
    UpdateBillingCost: (state, action) => {
      state.BillingCostLLM = action?.payload;
    },
    HandleAllUserDetails: (state, action) => {
      state.AllUserDetails = action?.payload;
    },
    HandleUpdateEncypt: (state, action) => {
      state.encyptData = action?.payload;
    },
    UpdateCost: (state, action) => {
      state.DashboardCardCost = action?.payload;
    },
    AdminDashboard: (state, action) => {
      state.DashboardGraph = action?.payload;
    },
    HandleLLMUserData: (state, action) => {
      state.UsageLLMUser = action?.payload;
    },
    GetHandleQrCodeUniqueId: (state, action) => {
      state.getuseruniqueId = action?.payload;
    },
    GetTiktokUserData: (state, action) => {
      state.gettiktokuserdetails = action?.payload;
    },
  },
});

export const {
  HandleEmaildata,
  UpdateCost,
  GetTiktokUserData,
  AdminDashboard,
  HandleUpdateEncypt,
  HandleAllUserDetails,
  HandleS3BucketImage,
  HandleGoogleSigninData,
  HandleProfileDetails,
  SetLoading,
  HandleMaildata,
  HandleInstagramUserData,
  HandleHashedTokenId,
  HandleTokenIdGenerate,
  HandleWalletBalance,
  HandleWalletDetails,
  HandleUpdateUserRole,
  HandleTransactionHistory,
  HandleUserPrivatekeyDetails,
  SetBillingCostOpenAI,
  HandleSetBillingCostOpenAI,
  HandleStabilityDiffusionCost,
  HandleUserlimit,
  HandlegetServerCostGraph,
  SetBillingCostGemini,
  HandleGetOpenAiLimit,
  HandlegetOpenAiCostGraph,
  HandleGetUserStabilityDiffusionLimitSet,
  HandlegetStabilityCostGraph,
  HandleGeminiLimit,
  HandlegetGeminiCostGraph,
  SetLLMError,
  HandleProfileDetailsAdmin,
  HandleLLMUserData,
  HandleAwsDetailsData,
  HandleAllFaq,
  HandleQrCode,
  GetHandleQrCode,
  GetHandleQrCodeUniqueId,
} = globalSlice.actions;

export default globalSlice.reducer;

export function ReGenerateToken(ApiFunction, body, dispatch) {
  return async () => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/refreshAccessToken`;
      const data = {
        email: GetDecryptEmailformat() || localStorage?.getItem("email"),
        reference_number: GetDecryptReferenceNumberformat() || localStorage?.getItem("reference_number"),
      };
      const response = await axios.post(url, data);

      if (response?.data) {
        SetLoading(false);

        SetEncryptAccessTokenformat(response?.data?.access_token);
        if (ApiFunction) {
          dispatch(ApiFunction(body));
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      SetLoading(false);
    } finally {
      SetLoading(false);
    }
  };
}

export function ReGenerateTokenAdmin(ApiFunction, body, dispatch) {
  const token = localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/adminRefreshToken`;
      const data = {
        token: localStorage?.getItem("refresh_token"),
      };
      const response = await axios.post(url, data, reqoptions);

      if (response?.data) {
        SetLoading(false);

        localStorage.setItem("access_token", response.data.token);
        if (ApiFunction) {
          dispatch(ApiFunction(body));
        }
      }
    } catch (error) {
      SetLoading(false);
    } finally {
      SetLoading(false);
    }
  };
}

export function LoginHandler({ data, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/googleSignIn`;
      const response = await axios.post(url, data);
      if (response?.data) {
        dispatch(SetLoading(false));
        SetEncryptEmailformat(response?.data?.data[0].email);
        SetEncryptReferenceNumberformat(response?.data?.data[0]?.reference_number);
        SetEncryptAccessTokenformat(response?.data?.access_token);
        SetUserData(response?.data?.data[0]?.username);
        dispatch(globalSlice?.actions?.HandleEmaildata(response?.data?.data[0].email));
        dispatch(globalSlice?.actions.HandleGoogleSigninData(response?.data?.data));
        if (response?.data?.data[0]?.email_verified === 0) {
          navigate("/verifyotp");
        } else if (response?.data?.data[0]?.phone_verified === 0) {
          navigate("/otp");
        } else {
          dispatch(globalSlice?.actions?.HandleUpdateUserRole("USER"));
          navigate("/dashboard");
        }
      }
    } catch (error) {
      if (error.response.data?.message === "Login has failed. Email has not been verified.") {
        dispatch(globalSlice?.actions.HandleGoogleSigninData(error?.response?.data?.data));
        SetEncryptEmailformat(error?.response?.data?.data[0]?.email);
        SetEncryptReferenceNumberformat(error?.response?.data?.data[0]?.reference_number);
        SetEncryptAccessTokenformat(error?.response?.data?.access_token);
        SetUserData(error?.response?.data?.data[0]?.username);

        if (error.response?.data?.data[0]?.email_verified === 0) {
          navigate("/verifyotp");
        } else if (error?.response?.data?.data[0]?.phone_verified === 0) {
          navigate("/otp");
        } else {
          navigate("/dashboard");
          dispatch(globalSlice?.actions?.HandleUpdateUserRole("USER"));
        }
      } else {
        toast.error(error?.response?.data?.message);
      }
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function RegisterUser({ registerData, dispatch, callback, navigate }) {
  return async () => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/signUp`;
      const response = await axios.post(url, registerData);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        if (response?.data?.error) {
          toast.info(response?.data?.message);
        }

        if (callback) callback(response?.data);

        navigate("/");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg);
      dispatch(SetLoading(false));
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function LoginUser({ loginData, navigate, dispatch, Callback }) {
  return async () => {
    try {
      let email = GetDecryptEmailformat();

      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/signIn`;
      const response = await axios.post(url, loginData);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions.HandleGoogleSigninData(response?.data?.data));
        SetEncryptEmailformat(response?.data?.data[0].email);
        SetEncryptReferenceNumberformat(response?.data?.data[0]?.reference_number);
        SetEncryptAccessTokenformat(response?.data?.access_token);
        SetUserData(response?.data?.data[0]?.username);
        if (Callback) {
          setTimeout(() => {
            if (email) {
              Callback();
            }
          }, 1000);
        }
        if (response?.data?.data[0]?.email_verified === 0) {
          navigate("/verifyotp");
        } else if (response?.data?.data[0]?.phone_verified === 0) {
          navigate("/otp");
        } else {
          navigate("/dashboard");
          dispatch(globalSlice?.actions?.HandleUpdateUserRole("USER"));
        }
        return response?.data;
      }
    } catch (error) {
      if (error.response.data?.message === "Login has failed. Email has not been verified.") {
        dispatch(globalSlice?.actions.HandleGoogleSigninData(error?.response?.data?.data));
        SetEncryptEmailformat(error?.response?.data?.data[0]?.email);
        SetEncryptReferenceNumberformat(error?.response?.data?.data[0]?.reference_number);
        SetEncryptAccessTokenformat(error?.response?.data?.access_token);
        SetUserData(error?.response?.data?.data[0]?.username);

        if (error.response?.data?.data[0]?.email_verified === 0) {
          navigate("/verifyotp");
        } else if (error?.response?.data?.data[0]?.phone_verified === 0) {
          navigate("/otp");
        } else {
          navigate("/dashboard");
          dispatch(globalSlice?.actions?.HandleUpdateUserRole("USER"));
        }
      } else {
        toast.error(error?.response?.data?.message);
      }
      dispatch(SetLoading(false));
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function LogoutHandler({ obj, navigate, dispatch, socket }) {
  const token = GetDecryptAccessTokenformat();

  const uniqId = GetDecryptUniqId();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/signOut`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));

        if (socket) {
          socket.emit("logout", {
            uniqueId: uniqId,
          });
        }

        localStorage?.removeItem("email");
        localStorage?.removeItem("access_token");
        localStorage?.removeItem("reference_number");
        localStorage?.removeItem("user_id");
        localStorage?.removeItem("UserName");
        sessionStorage?.removeItem("username");
        sessionStorage?.removeItem("password");
        localStorage?.removeItem("uniqId");
        dispatch(globalSlice?.actions?.HandleWalletDetails(""));
        dispatch(globalSlice?.actions?.HandleInstagramUserData(""));
        dispatch(globalSlice?.actions?.HandleTokenIdGenerate(""));
        dispatch(globalSlice?.actions?.HandleHashedTokenId(""));
        dispatch(globalSlice?.actions?.HandleInstagramUserProfileData(""));
        dispatch(globalSlice?.actions?.HandleUpdateHasInstaAccess(false));
        dispatch(globalSlice?.actions?.HandleUpdateUserRole(null));
        toast.success(response?.data?.message);
        localStorage.removeItem("role");
        navigate("/");
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, navigate, dispatch };
        dispatch(ReGenerateToken(LogoutHandler, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AddPhoneNumber({ obj, dispatch, callback }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/addPhoneNumber`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.data?.message);
        if (callback) callback(response?.data);
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch, callback };
        dispatch(ReGenerateToken(AddPhoneNumber, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message === "Invalid phone." && "Invalid phone number");
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function ConfirmEmail({ obj, navigate, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/confirmEmail`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        if (localStorage.getItem("isManual")) {
          navigate("/");
          localStorage.removeItem("isManual");
        } else {
          navigate("/otp");
        }
        localStorage.removeItem("isOtpSent");
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, navigate, dispatch };
        dispatch(ReGenerateToken(ConfirmEmail, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function VerifiedPhone({ obj, navigate, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/verifyPhoneNumber`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        navigate("/dashboard");
        return response?.data;
      }
    } catch (error) {
      if (dispatch) {
        dispatch(SetLoading(false));
      }
    }
  };
}

export function UpdateProfile({ obj, navigate, dispatch, callback }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/updateUserProfile`;
      const response = await axios.patch(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetProfile({ dispatch, obj }));
        toast.success(response?.data?.message);
        if (callback) {
          dispatch(callback(response.data));
        }
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, navigate, dispatch, callback };
        dispatch(ReGenerateToken(UpdateProfile, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetProfile({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      if (location.pathname === "/profile") {
        dispatch(SetLoading(true));
      }

      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/getUserProfile?reference_number=${obj?.reference_number}&email=${obj?.email}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleProfileDetails(response?.data?.data));
        dispatch(globalSlice?.actions?.HandleUpdateUserRole("USER"));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetProfile, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function RequestEmailOtp({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/requestEmailOtp`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        localStorage.setItem("isOtpSent", true);
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      if (error?.response?.data?.message && error?.response?.data?.success) {
        toast.success(error?.response?.data?.message);
      } else {
        toast.error(error?.message);
      }
      if (dispatch) {
        dispatch(SetLoading(false));
      }
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function InstagramHandler({ obj, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/auth/instagram`;
      const response = await axios.post(url, obj);

      if (response?.data) {
        dispatch(SetLoading(false));
        if (response?.data?.success === true) {
          toast.success(response?.data?.message);

          window.open(response?.data?.data, "AreaX", "height=500,width=500,left=450,top=100");
        } else {
          toast.error(response?.data?.message);
        }

        return response?.data;
      }
    } catch (error) {
      if (error?.response?.data?.message && error?.response?.data?.success) {
        toast.success(error?.response?.data?.message);
      } else {
        toast.error(error?.response?.data?.message);
      }
      if (dispatch) {
        dispatch(SetLoading(false));
      }
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function InstagramRevoke({ obj, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/auth/instagram`;
      const response = await axios.post(url, obj);
      if (response?.data) {
        dispatch(SetLoading(false));
        if (response?.data?.success === true) {
          toast.success(response?.data?.message);

          window.open(response?.data?.data, "AreaX", "height=500,width=500,left=450,top=100");
        } else {
          toast.error(response?.data?.message);
        }
        return response?.data;
      }
    } catch (error) {
      if (error?.response?.data?.message && error?.response?.data?.success) {
        toast.success(error?.response?.data?.message);
      } else {
        toast.error(error?.response?.data?.message);
      }
      if (dispatch) {
        dispatch(SetLoading(false));
      }
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetGmailAuth({ obj, dispatch }) {
  const reqoptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/google-api/authorize`;
      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        if (response?.data?.message) {
          window.location.href = response?.data?.message;
        }

        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetGmailAuth, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function SearchByMailWords({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/google-api/fetch_emails`;
      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        if (response?.data?.message) {
          dispatch(globalSlice?.actions?.HandleMaildata(response?.data?.message));
        }
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(SearchByMailWords, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UploadPostInstagram({ instaonchangedata, dispatch, fileupload, email, referenceNumber, obj }) {
  const token = GetDecryptAccessTokenformat();
  return async () => {
    const reqoptions = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/caption-image`;

      let form_data = new FormData();
      form_data.append("username", instaonchangedata?.username);
      form_data.append("password", instaonchangedata?.password);
      form_data.append("caption", instaonchangedata?.caption);
      form_data.append("image", fileupload);
      form_data.append("reference_number", referenceNumber);
      form_data.append("email", email);

      const response = await axios.post(url, form_data, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        SetEncryptUserNameformat(instaonchangedata?.username);
        SetEncryptPasswordformat(instaonchangedata?.password);
        toast.success(response?.data?.message);
        dispatch(GetInstagramDetails({ email, referenceNumber, dispatch }));
        dispatch(GetInstagramUserDetailsInfo({ obj, dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = {
          instaonchangedata,
          dispatch,
          fileupload,
          email,
          referenceNumber,
          obj,
        };
        dispatch(ReGenerateToken(UploadPostInstagram, body, dispatch));
        return;
      }
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetInstagramDetails({ email, referenceNumber, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(globalSlice?.actions?.SetInsiteLoading(true));
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/getInstagramBasicInfo?email=${email}&reference_number=${referenceNumber}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleInstagramUserData(response?.data?.data));
        dispatch(globalSlice?.actions?.HandleUpdateHasInstaAccess(response?.data?.success));
        dispatch(globalSlice?.actions?.SetInsiteLoading(false));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      dispatch(globalSlice?.actions?.SetInsiteLoading(false));
      if (error?.response?.status === 401) {
        let body = { email, referenceNumber, dispatch };
        dispatch(ReGenerateToken(GetInstagramDetails, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GeneratedCaptionApi({ email, referenceNumber, image, dispatch, obj }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      // let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/upload/imageFile`;
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_caption`;
      let form_data = new FormData();
      form_data.append("image", image);
      form_data.append("reference_number", referenceNumber);
      form_data.append("email", email);
      const response = await axios.post(url, form_data, reqoptions);

      if (response?.data?.status === 200) {
        dispatch(SetLoading(false));
        dispatch(UploadToBucket({ obj }));
        dispatch(globalSlice?.actions?.HandleImageCaptionGenerate(response?.data?.caption));

        if (response?.data?.total_cost) {
          let obj = {
            email: email,
            reference_number: referenceNumber,
            total_cost: response?.data?.total_cost,
            service: "GEMINI",
          };
          dispatch(OpenAIandGeminiUserDataSave({ obj, dispatch }));
        }

        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { email, referenceNumber, image, dispatch, obj };
        dispatch(ReGenerateToken(GeneratedCaptionApi, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UploadToBucket({ obj }) {

  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      // let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/upload/imageFile`;
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/uploadToBucket`;
      let form_data = new FormData();
      form_data.append("file", obj?.file);
      form_data.append("file_name", obj?.file_name);
      form_data.append("file_type", obj?.file_type);
      const response = await axios.post(url, form_data, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleS3BucketImage(response?.data?.file_url));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { obj, dispatch };
        dispatch(ReGenerateToken(UploadToBucket, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function InstagramLoginDetails({ obj, TokenID, getUserWalletDetails, walletBalanceUser }) {
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/private-user-login`;

      const response = await axios.post(url, obj);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        SetEncryptUserNameformat(obj?.username);
        SetEncryptPasswordformat(obj?.password);
        dispatch(
          GetInstagramUserDetailsInfo({
            obj,
            dispatch,
            TokenID,
            getUserWalletDetails,
            walletBalanceUser,
          })
        );

        dispatch(SetLoading(false));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = {
          obj,
          dispatch,
          TokenID,
          getUserWalletDetails,
          walletBalanceUser,
        };
        dispatch(ReGenerateToken(InstagramLoginDetails, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetInstagramUserDetailsInfo({ obj, TokenID, getUserWalletDetails, walletBalanceUser }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      dispatch(globalSlice?.actions?.SetInsiteLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/private-user-profile-insight`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        dispatch(SetLoading(false));
        // SetEncryptUserNameformat(obj?.username);
        // SetEncryptPasswordformat(obj?.password);
        dispatch(globalSlice?.actions?.HandleInstagramUserProfileData(response?.data?.data));
        dispatch(globalSlice?.actions?.SetInsiteLoading(false));

        if (walletBalanceUser && walletBalanceUser !== "You have not enough balance 0.,please add more balance.") {
          let comment_count = localStorage.getItem("CommentCount") || "200";
          let Multiple_Count = localStorage.getItem("MultipleCount") || "20";
          let isAutoMinting = localStorage.getItem("isAutoMinting");

          let commentMoreThen200 = response?.data?.data?.insights?.filter((item) => item?.comment_count >= Number(comment_count) && !item?.is_minted);
          commentMoreThen200 = commentMoreThen200.map((item) => ({
            walletAddress: getUserWalletDetails,
            id: item.media_item_id,
            postUrl: item.share_link,
            platformType: item.share_link.split(".")[1],
            multiple: false,
            txnMethod: "Auto Add Post",
          }));

          const formData = {
            fbUsername: "0",
            instaUsername: obj?.username,
            linkedinUsername: "0",
            xUsername: "0",
            fbFollowers: "0",
            instaFollowers: `${response?.data?.data?.followersCount}` || "0",
            linkedinFollowers: "0",
            xFollowers: "0",
            walletAddress: getUserWalletDetails,
            txnMethod: "Auto Add Mint",
          };

          let postMultipledetails = null;
          const hasLength = response?.data?.data?.insights?.length;
          const insightsData = hasLength ? response?.data?.data?.insights.slice().reverse() : [];
          const lastPost = insightsData?.find((obj) => obj?.is_already_mint_for_multiple !== 0);

          let lastCount = 0;
          if (lastPost) {
            lastCount = parseInt(lastPost?.is_already_mint_for_multiple);
          }

          if (response?.data?.data?.insights?.length >= (lastCount + Number(Multiple_Count) || 20)) {
            const subArray = [...(response?.data?.data?.insights ? [...response?.data?.data?.insights] : [])].slice(lastCount);
            postMultipledetails = {
              postUrl: subArray.map((item) => item?.share_link),
              platformType: subArray.map((item) => item?.share_link.split(".")[1]),
              multiple: true,
              id: response?.data?.data?.insights[hasLength - 1]?.media_item_id,
              is_already_mint_for_multiple: hasLength,
              walletAddress: getUserWalletDetails,
              txnMethod: "Auto Add Multiple Post",
            };
          }

          if (TokenID && isAutoMinting) {
            if (commentMoreThen200 && commentMoreThen200?.length) {
              const postdetails = commentMoreThen200?.map((item) => ({
                ...item,
                userTokenID: TokenID,
              }));
              dispatch(AddPost({ postdetails, dispatch }));
            }

            if (postMultipledetails) {
              dispatch(
                AddMultiplePost({
                  postMultipledetails: {
                    ...postMultipledetails,
                    userTokenID: TokenID,
                  },
                  dispatch,
                })
              );
            }
            return response?.data;
          }

          if (TokenID) {
            return;
          }

          dispatch(
            AddMinting({
              formData,
              isAutoMinting,
              commentMoreThen200,
              postMultipledetails,
              dispatch,
            })
          );
        } else {
          return response?.data;
        }

        return response?.data;
      }
    } catch (error) {
      dispatch(globalSlice?.actions?.SetInsiteLoading(false));
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetInstagramUserDetailsInfo, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetInstagramHashTag({ objhashtag, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/messaging/private-hashtag`;
      const response = await axios.post(url, objhashtag, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { objhashtag, dispatch };
        dispatch(ReGenerateToken(GetInstagramHashTag, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function PostTokenId({ obj, commentMoreThen200, isAutoMinting, postMultipledetails }, callBack) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      dispatch(globalSlice?.actions?.SetInsiteLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/addBlockchainTokenId`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success) {
        dispatch(GetTokenId({ obj }, callBack));
        dispatch(SetLoading(false));
        const FunCallBack = (token_id) => {
          if (commentMoreThen200 && commentMoreThen200.length && isAutoMinting) {
            const postdetails = commentMoreThen200.map((item) => ({
              ...item,
              userTokenID: token_id,
            }));
            dispatch(AddPost({ postdetails, dispatch }));
          }
          if (postMultipledetails && isAutoMinting) {
            dispatch(
              AddMultiplePost({
                postMultipledetails: {
                  ...postMultipledetails,
                  userTokenID: token_id,
                },
                dispatch,
              })
            );
          }
          callBack(token_id);
        };
        dispatch(PostTokenId({ obj }, FunCallBack));
        return response?.data;
      }
    } catch (error) {
      dispatch(globalSlice?.actions?.SetInsiteLoading(false));
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(PostTokenId, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetTokenId({ obj }, callBack) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/getBlockchainTokenId?reference_number=${obj?.reference_number}&email=${obj?.email}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        if (callBack) {
          callBack(response?.data?.data?.token_id);
        }

        dispatch(globalSlice?.actions?.HandleHashedTokenId(response.data.data.hashed_token_id));
        dispatch(globalSlice?.actions?.HandleTokenIdGenerate(response?.data?.data.token_id));
        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (callBack) {
        callBack(null);
      }
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetTokenId, body, dispatch));
        return;
      }
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetUserWalletDetails({ obj }) {
  const user_id = obj?.user_id;

  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/getUserWalletDetails?user_id=${obj?.user_id}`;
      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        // let userBal={
        //   walletAddress:obj?.walletAddress
        // }
        // dispatch( GetUserWalletBalance({obj}))
        dispatch(GetTokenId({ obj, dispatch }));
        dispatch(globalSlice?.actions?.HandleWalletDetails(response?.data?.data?.walletAddress));
        dispatch(globalSlice?.actions?.HandleUserPrivatekeyDetails(response?.data?.data?.privateKey));

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetUserWalletDetails, body, dispatch));
        return;
      }

      if (error?.response?.data?.message === "User wallet details not found") {
        dispatch(UserWalletDetails({ user_id, dispatch, obj }));
      }
    } finally {
      dispatch(SetLoading(false));
    }
  };
}
export function GetUserWalletBalance({ getUserWalletDetails }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let objBalance = {
    walletAddress: getUserWalletDetails,
  };
  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/userWalletBalance`;
      const response = await axios.post(url, objBalance, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleWalletBalance(response?.data?.amount));

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { getUserWalletDetails, dispatch };
        dispatch(ReGenerateToken(GetUserWalletBalance, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AddMinting({ formData, commentMoreThen200, isAutoMinting, postMultipledetails }) {
  const token = GetDecryptAccessTokenformat();
  const email = GetDecryptEmailformat();
  const reference_number = GetDecryptReferenceNumberformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/addMint`;
      const response = await axios.post(url, formData, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        const tokenId = response?.data?.data?.tokenID;

        if (!tokenId) {
          console.error("Token ID is undefined");
          return;
        }

        let obj = {
          email: email,
          reference_number: reference_number,
          token_id: `${tokenId}`,
        };

        dispatch(
          PostTokenId({
            obj,
            commentMoreThen200,
            isAutoMinting,
            postMultipledetails,
            dispatch,
          })
        );

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { formData, dispatch };
        dispatch(ReGenerateToken(AddMinting, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AddPost({ postdetails, callBack }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/addPost`;
      for (const [index, item] of postdetails.entries()) {
        const response = await axios.post(url, item, reqoptions);

        if (response?.data?.success === true) {
          const mintUrl = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/mint-image`;
          const body = {
            media_item_id: item?.id,
            is_minted: "1",
          };
          await axios.post(mintUrl, body, reqoptions);
          if (callBack) {
            callBack(response?.data);
          }
        }

        if (index === postdetails.length - 1) {
          dispatch(SetLoading(false));
        }
      }
    } catch (error) {
      if (callBack) {
        callBack(null);
      }
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { postdetails, dispatch };
        dispatch(ReGenerateToken(AddPost, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AddMultiplePost({ postMultipledetails }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/multiplePost`;
      const response = await axios.post(url, postMultipledetails, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        const mintMultipleurl = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/mint-multiple`;
        const body = {
          media_item_id: postMultipledetails?.id || postMultipledetails?.media_item_id,
          is_already_mint_for_multiple: postMultipledetails?.is_already_mint_for_multiple,
        };
        await axios.post(mintMultipleurl, body, reqoptions);

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { postMultipledetails, dispatch };
        dispatch(ReGenerateToken(AddMultiplePost, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetTransactionHistory({ address }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/getUserWalletCompleteDetails?walletAddress=${address} `;
      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(globalSlice?.actions?.HandleTransactionHistory(response?.data?.data?.walletDetails));
        dispatch(SetLoading(false));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateToken(GetTransactionHistory, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function OpenAPIBillingCost() {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_Chat`;
      const response = await axios.get(url, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.SetBillingCostOpenAI(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateToken(OpenAPIBillingCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetApiStatus({ dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));

      const urls = [
        `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getServerDailyCost1`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_Chat`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_sd_image`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_cost`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/image_generation`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/llama_Status`,
      ];

      const requests = urls.map((url) => axios.get(url, reqOptions).catch((error) => ({ error })));

      const responses = await Promise.all(requests);

      const result = responses.map((response, index) => {
        if (response.error) {
          return {
            name: getApiNameByIndex(index),
            status: "Deactive",
            total_cost: null,
            statuscode: response.error.response?.status || null,
          };
        }

        switch (index) {
          case 0:
            return {
              name: "AWS",
              status: response.data.success ? "success" : "failed",
              total_cost: parseFloat(response.data.data),
              status: "Active",
              statuscode: response?.status,
            };

          case 1:
            return {
              name: "OpenAI",
              status: response.data.status,
              total_cost: response.data.total_cost,
              status: "Active",
              statuscode: response?.status,
            };
          case 2:
            return {
              name: "Stablity",
              status: response.data.status,
              total_cost: response.data.total_cost_in_dollar,
              status: "Active",
              statuscode: response?.status,
            };

          case 3:
            return {
              name: "GeminiAI",
              status: response.data.status === 200 ? "success" : "failed",
              total_cost: response.data.total_cost,
              status: "Active",
              statuscode: response?.status,
            };
          case 4:
            return {
              name: "Flux",
              status: response.data.status,
              total_cost: "30",
              status: "Active",
              statuscode: response?.status,
            };
          case 5:
            return {
              name: "Llama 3.2",
              status: response.data.status,
              total_cost: "30",
              status: "Active",
              statuscode: response?.status,
            };
          default:
            return {
              name: `unknown_response_${index}`,
              status: "unknown",
              total_cost: 0,
            };
        }
      });

      dispatch(globalSlice?.actions?.UpdateApiStatus(result));
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetApiStatus, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

const getApiNameByIndex = (index) => {
  switch (index) {
    case 0:
      return "AWS";
    case 1:
      return "OpenAI";
    case 2:
      return "Stability";
    case 3:
      return "GeminiAI";
    case 4:
      return "Flux";
    case 5:
      return "Llama 3.2";
    default:
      return `unknown_response_${index}`;
  }
};
export function ChangePassWord({ obj }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/changePassword`;

      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);

        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(ChangePassWord, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function ForgotPasswordEmailOtp({ obj, navigate }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/forgotPasswordEmailOtp`;

      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        sessionStorage.setItem("email", response?.data?.data[0]?.email);
        sessionStorage.setItem("reference_number", response?.data?.data[0]?.reference_number);
        sessionStorage.setItem("access_token", response?.data?.access_token);
        toast.success(response?.data?.message);
        navigate("/resetpassword");
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(ForgotPasswordEmailOtp, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function ResetPasswordApi({ obj, navigate }) {
  const token = sessionStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/forgotPassword`;

      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("reference_number");
        sessionStorage.removeItem("token");
        toast.success(response?.data?.message);
        navigate("/");
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(ResetPasswordApi, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UploadProfilePicture({ objforupdatepic, dispatch, obj }) {
  const token = GetDecryptAccessTokenformat();
  const formData = new FormData();
  formData.append("email", objforupdatepic?.email);
  formData.append("reference_number", objforupdatepic?.reference_number);
  formData.append("image", objforupdatepic?.image);

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/uploadUserProfilePicture`;
      const response = await axios.post(url, formData, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetProfile({ dispatch, obj }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { objforupdatepic, dispatch };
        dispatch(ReGenerateToken(UploadProfilePicture, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UserWalletDetails({ user_id, obj }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/userWalletDetail`;
      let objdata = {
        user_id: user_id,
      };
      const response = await axios.post(url, objdata, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetUserWalletDetails({ obj, dispatch }));
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetServerDailyCost() {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getServerDailyCost1`;
      const response = await axios.get(url, reqoptions);
      if (response?.data?.success === true) {
        const blendedCost = response?.data?.data;
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleServerDailyCost(blendedCost));
        return response?.data;
      } else {
        console.error("API call was not successful:", response?.data?.message);
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        dispatch(ReGenerateToken(GetServerDailyCost, {}, dispatch));
        return;
      }
      console.error(error?.response?.data?.message || "An error occurred", "errrrrrrrr");
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function StabilityDiffusionCost() {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_sd_image`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleStabilityDiffusionCost(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateToken(StabilityDiffusionCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GeminiBillingCost() {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_cost`;
      const response = await axios.get(url, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.SetBillingCostGemini(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateToken(OpenAPIBillingCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function SetUserLimit({ obj }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userLimitSet`;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(GetUserLimit({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(SetUserLimit, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetUserLimit({ body }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleUserlimit(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateTokenAdmin(GetUserLimit, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getServerCostGraph({ currentYearAWS, currentYear }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getServerCostGraph/${currentYearAWS || currentYear}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetServerCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getServerCostGraph, { currentYearAWS }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function SetOpenAiLimit({ obj }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userOpenAiLimitSet `;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(GetOpenAiLimit({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(SetOpenAiLimit, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetOpenAiLimit({ body }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserOpenAiLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleGetOpenAiLimit(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateTokenAdmin(GetOpenAiLimit, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getOpenAiCostGraph({ currentYearOpenAI }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getOpenAiCostGraph/${currentYearOpenAI}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetOpenAiCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getOpenAiCostGraph, { currentYearOpenAI }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function setuserStabilityDiffusionLimitSet({ obj }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userStabilityDiffusionLimitSet `;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(getUserStabilityDiffusionLimitSet({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(setuserStabilityDiffusionLimitSet, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getUserStabilityDiffusionLimitSet({ body }) {
  const tokenAdmin = localStorage?.getItem("access_token");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserStabilityDiffusionLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleGetUserStabilityDiffusionLimitSet(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateToken(getUserStabilityDiffusionLimitSet, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getStabilityCostGraph({ currentYearStability }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getStabilityCostGraph/${currentYearStability}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetStabilityCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getStabilityCostGraph, { currentYearStability }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function SetGeminiLimit({ obj }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };

  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userGeminiLimitSet `;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(GetGeminiLimit({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(SetGeminiLimit, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetGeminiLimit({ body }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserGeminiLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleGeminiLimit(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateTokenAdmin(GetGeminiLimit, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getGeminiCostGraph({ currentYearGemini }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getGeminiCostGraph/${currentYearGemini}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetGeminiCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getGeminiCostGraph, { currentYearGemini }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetbillingStatusDATA({ dispatch, fromAndToDate }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));

      let urls = [];

      if (fromAndToDate[0] && fromAndToDate[1]) {
        urls = [
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/openai_daterange_cost?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/sd_daterange_cost?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_daterange_cost?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
          `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
        ];
      } else {
        urls = [
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/openai_daterange_cost`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/sd_daterange_cost`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_daterange_cost`,
          `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise`,
        ];
      }

      const requests = urls.map((url) => axios.get(url, reqOptions));
      const responses = await Promise.all(requests);

      const result = responses.map((response, index) => {
        const isDateRangeSelected = fromAndToDate[0] && fromAndToDate[1];

        switch (index) {
          case 0:
            return {
              name: "OpenAI",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
              month: isDateRangeSelected ? "" : new Date().toLocaleString("default", { month: "long" }),
              startmonth: response?.data?.start_month,
              endmonth: response?.data?.end_month,
              year: isDateRangeSelected ? "" : new Date().getFullYear(),
              startyear: response?.data?.start_year,
              endyear: response?.data?.end_year,
            };
          case 1:
            return {
              name: "Stability",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 2:
            return {
              name: "Gemini",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 3:
            return {
              name: "AWS",
              status: response.data.status,
              total_cost: response.data.total_cost ? `$${response.data.total_cost}` : "$ 0.0000",
              statuscode: response?.status,
              month: isDateRangeSelected ? "" : response?.data?.month_year?.month,
              startmonth: response?.data?.month_year?.start_month,
              endmonth: response?.data?.month_year?.end_month,
              year: isDateRangeSelected ? "" : response?.data?.month_year?.year,
              startyear: response?.data?.month_year?.start_year,
              endyear: response?.data?.month_year?.end_year,
            };
          default:
            return {
              name: `unknown_response_${index}`,
              status: "unknown",
              total_cost: 0,
            };
        }
      });

      const parseCost = (costString) => parseFloat(costString.replace("$", "")) || 0;
      const totalCostSum = result.reduce((acc, curr) => acc + parseCost(curr.total_cost), 0);

      result.push({
        name: "Total",
        status: "N/A",
        total_cost: `$${totalCostSum.toFixed(4)}`,
        statuscode: "N/A",
      });

      dispatch(globalSlice?.actions?.UpdateBillingCost(result));
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetApiStatus, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// *******************changed added today*************************
export function getServerCostDataAws({ dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleAwsDetailsData(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getServerCostDataAws, { dispatch }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// ************************************

export function OpenAIandGeminiUserDataSave({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/saveUserOpenAiGeminiUsageCost`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.data?.message);
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(OpenAIandGeminiUserDataSave, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}
//  *********************************
export function GetOpenAIandGeminiUserDataSave({ dispatch, currentYearUserReport, currentMonthDataReport }) {
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${tokenAdmin}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getAllUserOpenAiGeaminiUsageCost?year=${currentYearUserReport}&month=${currentMonthDataReport}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleLLMUserData(response.data.data));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetOpenAIandGeminiUserDataSave, bodydata, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// *******************************ADMIN API**********************************************

export function AdminLogin({ navigate, objData }) {
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/signIn`;

      const response = await axios.post(url, objData);

      if (response?.data.success === true) {
        let EncryptedDataResponse = response.data.data;
        const result = DecryptedDataAdmin(EncryptedDataResponse);
        let email = EncryptDataAdmin(result.data.email);
        let reference_number = EncryptDataAdmin(result?.data?.reference_number);
        let username = EncryptDataAdmin(result?.data?.username);
        localStorage.setItem("email", email);
        localStorage.setItem("reference_number", reference_number);
        localStorage.setItem("username", username);
        localStorage.setItem("access_token", result?.token);
        localStorage.setItem("refresh_token", result?.refresh_token);
        localStorage.setItem("userid", result?.data?._id);
        dispatch(globalSlice?.actions?.HandleUpdateUserRole(result?.data?.role));
        toast.success(response?.data?.message);
        dispatch(HandleUpdateUserRole(response?.data?.data?.role));
        dispatch(HandleUpdateEncypt(response.data.encryptedData));
        navigate("/admindashboard");
        dispatch(SetLoading(false));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { objData, dispatch };
        dispatch(ReGenerateToken(AdminLogin, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetProfileAdmin({ objData, dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getAdminProfile`;
      const response = await axios.post(url, objData, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleUpdateUserRole("admin"));

        let EncryptedDataResponse = response.data.data;
        const result = DecryptedDataAdmin(EncryptedDataResponse);

        dispatch(globalSlice?.actions?.HandleProfileDetailsAdmin(result));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { objData, dispatch };
        dispatch(ReGenerateTokenAdmin(GetProfileAdmin, body, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}
export function GetAllUserDetails({ dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getAllUsersList`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        let EncryptedDataResponse = response.data.data;
        const result = DecryptedDataAdmin(EncryptedDataResponse);
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleAllUserDetails(result?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetAllUserDetails, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UploadProfilePictureAdmin({ objforupdatepic, dispatch, objData }) {
  const token = localStorage?.getItem("access_token");
  const formData = new FormData();
  formData.append("userId", objforupdatepic?.userId);
  formData.append("profile_image", objforupdatepic?.profile_image);

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/uploadImg`;
      const response = await axios.post(url, formData, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetProfileAdmin({ dispatch, objData }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { objforupdatepic, dispatch, objData };
        dispatch(ReGenerateTokenAdmin(UploadProfilePictureAdmin, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function adminDashboardCost({ dispatch, carddate }) {
  const token = localStorage.getItem("access_token");
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let urls = [];
      urls = [
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/openai_daterange_cost?start_date=${carddate}&end_date=${carddate}`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/sd_daterange_cost?start_date=${carddate}&end_date=${carddate}`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_daterange_cost?start_date=${carddate}&end_date=${carddate}`,
        `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise?start_date=${carddate}&end_date=${carddate}`,
      ];
      const requests = urls.map((url) => axios.get(url, reqOptions));
      const responses = await Promise.all(requests);

      const result = responses.map((response, index) => {
        switch (index) {
          case 0:
            return {
              name: "OpenAI",
              status: response.data.success,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };

          case 1:
            return {
              name: "Stability",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 2:
            return {
              name: "Gemini",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 3:
            return {
              name: "AWS",
              status: response.data.status,
              total_cost: response.data.total_cost ? `$${response.data.total_cost}` : "$ 0.0000",
              statuscode: response?.status,
            };

          default:
            return {
              name: `unknown_response_${index}`,
              status: "unknown",
              total_cost: 0,
            };
        }
      });
      const parseCost = (costString) => parseFloat(costString.replace("$", "")) || 0;
      const totalCostSum = result.reduce((acc, curr) => acc + parseCost(curr.total_cost), 0);

      result.push({
        name: "Total",
        status: "N/A",
        total_cost: `$${totalCostSum.toFixed(4)}`,
        statuscode: "N/A",
      });

      dispatch(globalSlice?.actions?.UpdateCost(result));
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(adminDashboardCost, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AdminRegisterUersDetails({ dispatch, body }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getUserRegistrationsByMonth?year=${body?.changeyear}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        let EncryptedDataResponse = response.data.data;
        const result = AdminDashboardGraph(EncryptedDataResponse);
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.AdminDashboard(result));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(AdminRegisterUersDetails, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AdminLogout({ objData, dispatch, navigate }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/adminLogout`;
      const response = await axios.post(url, objData, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        navigate("/");
        localStorage.clear();
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { objData, dispatch };
        dispatch(ReGenerateTokenAdmin(GetProfileAdmin, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function CreateFaqApi({ obj, dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/createFaq`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetFaqAPI({ dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(CreateFaqApi, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetFaqAPI({ dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getAllFaq`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleAllFaq(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetFaqAPI, body, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function DeleteFaqAPI({ dispatch, obj }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/deletefaq`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetFaqAPI({ dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(DeleteFaqAPI, body, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UpdateFaqApi({ obj, dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/updateFaq`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetFaqAPI({ dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(CreateFaqApi, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GenerateQrCode({ dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/generateCode`;
      const response = await axios.post(url, {}, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleQrCode(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GenerateQrCode, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetQrCode({ dispatch, uniqueId }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getUserLoginData/${uniqueId}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.GetHandleQrCode(response?.data?.data ? response?.data?.data : response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch, uniqueId };
        dispatch(ReGenerateTokenAdmin(GetQrCode, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function VerifyQrcode({ dispatch, obj }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/verifyQrcode`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        // dispatch(globalSlice?.actions?.HandleQrCode(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch, obj };
        dispatch(ReGenerateTokenAdmin(VerifyQrcode, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetQrCodeByUniqueId({ dispatch, obj }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getQrDataByUniqeId`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.GetHandleQrCodeUniqueId(response));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch, obj };
        dispatch(ReGenerateTokenAdmin(GetQrCodeByUniqueId, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export const TikTokLoginHandler = ({ userData }) => {
  const token = GetDecryptAccessTokenformat();

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/auth/tiktok`;
      const response = await axios.post(url, userData, reqoptions);

      if (response) {
        window.location.href = response?.data?.data;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        let body = { userData };
        dispatch(ReGenerateToken(TikTokLoginHandler, body, dispatch));
        return;
      }
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      dispatch(SetLoading(false));
    }
  };
};

export const TikTokAuthCallback = ({ obj, navigate }) => {
  const token = GetDecryptAccessTokenformat();

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/auth/tiktok/callback`;

     
      const response = await axios.post(url, obj, reqoptions);

      if (response) {
        toast.success(response?.data?.message || "Error occurred");
        navigate("/tiktokuserprofile");
        return response.data;
      } else {
        toast.error(response?.data?.message || "Error occurred");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        let body = { dispatch, obj };
        dispatch(ReGenerateToken(TikTokAuthCallback, body, dispatch));
        return;
      }

      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      dispatch(SetLoading(false));
    }
  };
};

export function GetTiktokProfileData({ dispatch, userData }) {
  const token = GetDecryptAccessTokenformat();

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/tiktokUserProfile?email=${userData?.email}&reference_number=${userData?.reference_number}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.GetTiktokUserData(response?.data?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch, userData };
        dispatch(ReGenerateToken(GetTiktokProfileData, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function PosttiktokMedia({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  return async () => {
    const reqoptions = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/tiktok/api/v1/messaging/media`;

      let form_data = new FormData();
      form_data.append("image", obj?.image);
      form_data.append("reference_number", obj?.reference_number);
      form_data.append("email", obj?.email);
      form_data.append("title", obj?.title);
      form_data.append("description", obj?.description);

      const response = await axios.post(url, form_data, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        toast.success(response?.data?.message);

        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = {
          obj,
          dispatch,
        };
        dispatch(ReGenerateToken(PosttiktokMedia, body, dispatch));
        return;
      }
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      dispatch(SetLoading(false));
    }
  };
}
