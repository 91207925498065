import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Input } from "antd";
import { GetFaqAPI } from "../../redux/auth/authSlice";

import "../../style/admin.css";

const { Panel } = Collapse;

const Faq = () => {
  const dispatch = useDispatch();
  const { ALLfaq } = useSelector((state) => state.counter);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(GetFaqAPI({ dispatch }));
  }, [dispatch]);

  const HandleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredFaqs = ALLfaq?.data?.filter((faq) => faq?.faq_title?.toLowerCase()?.includes(searchQuery));

  return (
    <div className="privacyPolicy_css">
      <h1>Frequently Asked Questions (FAQ)</h1>

      <div style={{ marginBottom: 16 }}>
        <Input placeholder="Search FAQ" value={searchQuery} onChange={HandleSearch} style={{ width: 300, marginRight: 8 }} />
      </div>

      <Collapse accordion>
        {filteredFaqs?.map((ele) => (
          <Panel header={ele?.faq_title} key={ele._id}>
            <>
              <p>{ele?.faq_description}</p>
            </>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default Faq;
