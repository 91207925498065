import React, { useEffect } from "react";
import { Button } from "antd";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { TikTokAuthCallback } from "../../redux/auth/authSlice";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../Common/Common";
import { Layout, Typography } from "antd";
import "../../style/admin.css";
import logo from "../../Utils/Images/ProjectWlogo.svg";
import logo1 from "../../Utils/Images/dashboardimg.jpg";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Tiktokredirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const token = localStorage.getItem("access_token");
  const email = GetDecryptEmailformat();
  const reference_number = GetDecryptReferenceNumberformat();

  const HandleNav = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const authCode = queryParams.get("code");
  const authstate = queryParams.get("state");

  useEffect(() => {
    if (authCode && authstate) {
      const obj = {
        code: authCode,
        state: authstate,
        email: email,
        reference_number: reference_number,
      };

      dispatch(TikTokAuthCallback({ dispatch, obj, navigate }));
    }
  }, [authCode, authstate, dispatch]);

  return (
    <div style={{ padding: "50px" }}>
      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={HandleNav}
        style={{
          fontSize: "15px",
          position: "absolute",
          top: "16px",
          left: "16px",
          zIndex: "99999",
        }}
      >
        Go Back
      </Button>

      <Layout className="home-page">
        <div className="logo">
          <img src={logo} alt="Project W Logo" style={{ height: "40px" }} />
        </div>

        <Content className="content">
          <div className="hero-section">
            <img src={logo1} alt="AI Technology" className="hero-image" />
            <div className="hero-text">
              <Title>Welcome to Project W</Title>
              <Paragraph>
                Discover the future of AI with **Project W**, where innovation meets automation. Experience cutting-edge technology and stay ahead of the curve.
              </Paragraph>
              <Button>
                <Link to="/" type="primary" size="large">
                  Get Started
                </Link>
              </Button>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default Tiktokredirect;
