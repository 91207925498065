import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TikTokAuthCallback } from "../../redux/auth/authSlice";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../Common/Common";
import { useLocation } from "react-router-dom";

const AuthCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchAccessToken = async (authCode) => {
      try {
        const email = GetDecryptEmailformat();
        const reference_number = GetDecryptReferenceNumberformat();
        const queryParams = new URLSearchParams();
        queryParams.append("email", email);
        queryParams.append("reference_number", reference_number);

        const userData = {
          email,
          reference_number,
          code: authCode,
        };

        dispatch(TikTokAuthCallback(userData, location));
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    const queryParams = new URLSearchParams(window.location.search);
    const authCode = queryParams.get("code");

    if (authCode) {
      console.log(authCode, "Authorization Code Received");
      fetchAccessToken(authCode);
    } else {
      console.error("Authorization code not found");
    }
  }, [dispatch]);

  return <div>Authenticating...</div>;
};

export default AuthCallback;
