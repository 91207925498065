import React from "react";

import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const PrivacyPolicies = () => {
  const navigate = useNavigate();

  const token = localStorage.getItem("access_token");

  const HandleNav = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };
  return (
    <div className="pad-privasy" style={{ padding: "50px" }}>
      <div className="privacyPolicy_css">
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => HandleNav()}
          style={{
            fontSize: "15px",
            position: "absolute",
            top: "16px",
            left: "16px",
            zIndex: "99999",
          }}
        >
          Go Back
        </Button>
        <h1>Privacy Policy</h1>
        <p>Welcome to Project W.</p>
        <p>
          We at Project W (referred to as “we” “us” and “our”) care about your privacy and the protection of your personal data. We have drafted this Privacy Policy to inform you
          about our practices regarding your personal data. This document explains to you what personal data of yours we process, for what purpose we process it and what measures
          we take to ensure that your personal data is secure with us.
        </p>
        <p>By using our application, you give consent that you understand and agree to the terms laid down in this privacy policy.</p>
        <p>Project W is wholly owned by Area X LLC, registered in the United Arab Emirates.</p>
        <p>
          Our website address is <a href="https://projectw.ai">https://projectw.ai</a>.
        </p>
        <h2>Definitions</h2>
        <p>All capitalized terms seen below shall have the meaning as given in this section:</p>
        <ul>
          <li>
            <strong>PERSONAL DATA:</strong> This means any information relating to an identified or identifiable individual, to the extent that such information is protected as
            personal data under the Applicable Data Protection Law.
          </li>
          <li>
            <strong>DATA CONTROLLER:</strong> Means the entity which determines the purposes and means of the processing of Personal Data.
          </li>
          <li>
            <strong>DATA PROCESSOR:</strong> Means the entity which Processes Personal Data on behalf of the Data Controller.
          </li>
          <li>
            <strong>DATA SUBJECT:</strong> Means an identified or identifiable individual, who can be identified, directly or indirectly, in particular by reference to an
            identifier such as a name, an ID number, location data, an online ID, or to one or more factors specific to the physical, physiological, genetic, mental, economic,
            cultural, or social identity of that natural person.
          </li>
          <li>
            <strong>PERSONAL DATA BREACH:</strong> Means any unauthorized or unlawful breach of security leading to, or reasonably believed to, the unauthorized or accidental
            destruction, loss, alteration, unauthorized disclosure of or access to Personal Data.
          </li>
          <li>
            <strong>PROCESS OR PROCESSING:</strong> Means any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether by automated
            means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission,
            dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction.
          </li>
        </ul>
        <h2>The lawful basis for processing your data</h2>
        <p>We process your data based on Article 6(a) of the GDPR, which states:</p>
        <blockquote>“(a) the data subject has given consent to processing his or her personal data for one or more specific purposes;”</blockquote>
        <p>If we use personal information for a purpose that requires your consent by provisions of law, we will always ask for your explicit agreement and record your consent.</p>
        <p>
          By submitting Personal Information through our Site or Services, you agree to the terms of this Privacy Policy; you expressly consent to the collection, use, and
          disclosure of your Personal Information in accordance with this Privacy Policy.
        </p>
        <h2>What Personal Data do we collect and how?</h2>
        <p>
          Our primary goal is to collect information to provide and improve our Services, to deliver your use of the Services, and enable you to enjoy and easily navigate our
          Services.
        </p>
        <p>We collect two categories of data:</p>
        <ul>
          <li>
            <strong>Non-identifying information:</strong> This is collected as you navigate our application through cookies and other similar technologies, including the date and
            duration of your visit.
          </li>
          <li>
            <strong>Identifying information:</strong> This is collected when you interact with our services, such as creating an account or making a purchase. This includes your
            name, email address, etc.
          </li>
        </ul>
        <h2>We collect information in these three forms:</h2>
        <ul>
          <li>
            The information you have provided us with: data you have willingly given to us, such as; your username, password and email address when you register for an account.
          </li>
          <li>Information automatically collected about you includes information automatically stored by cookies and other session tools.</li>
          <li>
            Information collected from our partners: we gather information from our trusted partners with confirmation that they have legal grounds to share that information with
            us. This information is non-personal aggregated data that cannot be used to identify you. This is aggregated data that is used for statistical purposes to improve our
            services. This is information that you have provided willingly.
          </li>
        </ul>

        <h2>Aggregated Data</h2>
        <p>
          Aggregated data is information gathered and expressed in a summary form for purposes such as statistical analysis, and so is not personal data for the purposes of data
          protection law. Aggregated Data such as the total number of visitors in a given month, the date and duration of the visit, etc. This data is evaluated anonymously for
          statistical purposes to optimize our services. This data is not used to identify you and therefore does not violate any privacy laws.
        </p>
        <h2>Cookies</h2>
        <p>
          Internet Cookies are small pieces of data sent from a website and stored on the user’s computer by the user’s web browser while browsing. The aim of the Cookie is to
          remember the user and record their activity so that the website can offer tailored services to its users, making for a better browsing experience. These cookies will last
          for one year. If you wish you can configure your browser to refuse cookies or ask for confirmation beforehand. However, refusing cookies means that your browsing
          experience will no longer be as smooth and tailored to you.
        </p>
        <h2>Data collected when you use our services</h2>
        <p>
          Besides aggregated data and cookies, which are automatically collected when you visit our application, if you wish to subscribe to our mailing list so that you can
          receive the latest offers via email, we will collect your email address. This data is only used to send you the latest offers, and in case you contact us, it is used to
          reply to you. It will not be used for any other purpose. When you leave comments on the site or app, we collect the data shown in the comments form and your IP address
          and browser agent string to help spam detection. This data is not used to identify you.
        </p>
        <h2>What do we do with your information?</h2>
        <ul>
          <li>To provide, support, and improve our Services.</li>
          <p>
            This may include sharing your information with third parties in order to provide and support our Services, to make certain features of the Services available to you,
            thus improving your overall user experience.
          </p>
          <li>
            To provide customer support and answer questions.
            <p>Reply to your questions, inform you about your learning journey, our current promotions & special offers and other relevant queries.</p>
          </li>
          <li>
            To analyze statistical data.
            <p>We analyze this data so that we can improve our services; this data is non-identifiable data.</p>
          </li>
          <li>
            To meet legal requirements such as court orders or lawful requests.
            <p>
              This includes complying with court orders, valid discovery requests, valid subpoenas, prosecuting and defend a court, arbitration, or similar legal proceeding, to
              respond to lawful requests by public authorities, including to meet national security or law enforcement requirements and other appropriate legal mechanisms.
            </p>
          </li>
        </ul>
        <h2>How is your data protected?</h2>
        <p>
          We take the necessary technical and organizational measures to adequately protect the data of the Data Controller by meeting the requirements of Art. 32 of the GDPR. We
          use safe protocols for communication and transferring data (such as HTTPS). We use anonymizing, pseudonymization and encryption where suitable. We monitor our systems for
          possible vulnerabilities and attacks. No unauthorized access to processing systems is provided. Data is stored in highly secure data centers. Even though we try our best,
          we cannot guarantee information security. However, we promise to notify the proper authorities of data breaches. We will also notify you if there is a threat to your
          rights or interests. We will do everything we reasonably can to prevent security breaches and assist authorities should any breaches occur.
        </p>
        <h2>How long do we retain your data?</h2>
        <p>
          The data processed by us is deleted or limited in its processing in accordance with Articles 17 and 18 of GDPR. Unless explicitly stated in this privacy policy, the data
          stored by us are deleted as soon as they are no longer required for their purpose, and the deletion does not conflict with any statutory storage requirements.
        </p>
        <h2>Tools used to process your data</h2>
        <p>We use the following tools to offer our services:</p>
        <ul>
          <li>
            <strong>SendInBlue:</strong> We use the following tools so that we can offer our services to you: SendInBlue SendInBlue is an online marketing platform. They provide a
            range of services, such as real-time data analytics. We use them to collect the emails from our users to contact them about updates and news regarding Project W. For
            more information on how they protect your privacy, please visit their privacy policy at: SendInBlue Privacy Policy{" "}
            <a href="https://www.sendinblue.com/legal/privacypolicy/">here</a>.
          </li>
        </ul>
        <h2>Data Transfer</h2>
        <p>
          We do not share your Personal Data with strangers. All Personal Information collected via or by us is stored on Amazon Web Services servers, which may be stored anywhere
          in the world, including but not limited to, the United States, the European Union, in the cloud, on our servers, on the servers of our affiliates or the servers of our
          service providers. Your Personal Information may be accessible to law enforcement or other authorities pursuant to a lawful request. By providing information to us, you
          consent to store your Personal Information in these locations. We only work with Processing partners who are able to ensure a certified and legally compliant level of
          protection of your Personal Data or whom we have contractually obliged to guarantee that they will provide high data protection safeguards.
        </p>
        <h2>Your Rights as a Data Subject</h2>
        <ul>
          <li>The right to be informed;</li>
          <li>The right of access;</li>
          <li>The right to rectification;</li>
          <li>The right to erasure;</li>
          <li>The right to restrict processing;</li>
          <li>The right to data portability;</li>
          <li>The right to object;</li>
          <li>Rights related to automated decision-making and profiling.</li>
        </ul>
        <p>
          For more information or to exercise your rights, contact us at <a href="mailto:info@areax.ai">info@areax.ai</a>.
        </p>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          lPlease note that this Privacy Policy may change from time to time. If any major changes happen to this Privacy Policy, we will inform you within thirty (30) calendar
          days, via e-mail.
        </p>
        <h2>Further Information</h2>
        <p>
          If you require any more information or have any questions about our privacy policy,please feel free to contact us by email at{" "}
          <a href="mailto:info@areax.ai">info@areax.ai</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicies;
