import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddPost, GetInstagramUserDetailsInfo, GetTokenId } from "../../redux/auth/authSlice";
import { Badge, Button, Card, Input, Tooltip } from "antd";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";

import { GetDecryptEmailformat, GetDecryptPasswordformat, GetDecryptReferenceNumberformat, GetDecryptUserNameformat } from "../../Components/Common/Common";
import Meta from "antd/es/card/Meta";

const InstagramPosts = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);

  const [isLoading, setLoading] = useState({
    loading: false,
    media_item_id: null,
  });

  const dispatch = useDispatch();

  const { InstagramProfileData, getUserWalletDetails, walletBalanceUser } = useSelector((state) => state?.counter);
  const decryptusername = GetDecryptUserNameformat();

  const decryptpassword = GetDecryptPasswordformat();

  const email = GetDecryptEmailformat();

  const referenceNumber = GetDecryptReferenceNumberformat();

  useEffect(() => {
    let obj = {
      email: email,
      reference_number: referenceNumber,
      username: decryptusername,
      password: decryptpassword,
    };
    dispatch(GetInstagramUserDetailsInfo({ obj, dispatch }));
  }, []);

  useEffect(() => {
    if (InstagramProfileData?.insights) {
      if (searchQuery === "") {
        setFilteredPosts(InstagramProfileData.insights);
      } else {
        setFilteredPosts(InstagramProfileData.insights.filter((post) => post.caption.toLowerCase().includes(searchQuery.toLowerCase())));
      }
    }
  }, [searchQuery, InstagramProfileData]);

  const handleClickMintPost = (clickedPost) => {
    setLoading({ loading: true, media_item_id: clickedPost.media_item_id });

    const callBack = (id) => {
      if (!id) {
        setLoading({ loading: false, media_item_id: null });
        return;
      }
      const postdetails = [
        {
          walletAddress: getUserWalletDetails,
          id: clickedPost.media_item_id,
          postUrl: clickedPost.share_link,
          platformType: clickedPost.share_link.split(".")[1],
          multiple: false,
          txnMethod: "Manual Add Post",
          userTokenID: id,
        },
      ];
      const callBackAddPost = (result) => {
        if (!result) {
          setLoading({ loading: false, media_item_id: null });
          return;
        }
        const obj = {
          email: email,
          reference_number: referenceNumber,
          password: GetDecryptPasswordformat(),
          username: GetDecryptUserNameformat(),
        };
        setLoading({ loading: false, media_item_id: null });
        dispatch(
          GetInstagramUserDetailsInfo({
            obj,
            dispatch,
            TokenID: id,
            getUserWalletDetails,
            walletBalanceUser,
          })
        );
      };
      dispatch(AddPost({ postdetails, dispatch, callBack: callBackAddPost }));
    };

    const details = {
      email: email,
      reference_number: referenceNumber,
    };

    dispatch(GetTokenId({ obj: details }, callBack));
  };

  const handleOpenInstaLink = (share_link) => {
    window.open(share_link, "AreaX", "height=500,width=500,left=450,top=100");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Button type="text" icon={<ArrowLeftOutlined />} onClick={() => navigate("/instagram?shouldPost=true")} style={{ fontSize: "15px" }}>
          Go Back
        </Button>
        <Input placeholder="Search posts..." onChange={(e) => setSearchQuery(e.target.value)} suffix={<SearchOutlined />} style={{ width: 300 }} />
      </div>

      <div className="tab-content">
        <div id="post" className="container">
          <div className="row mt-4">
            {filteredPosts?.length ? (
              <div className="flex-repet">
                {filteredPosts.map((item, index) => (
                  <Badge.Ribbon color={item.is_minted ? "red" : "#17c1e8"} key={index} text={item.is_minted ? "Minted" : "NotMinted"} style={{ width: "32%" }}>
                    <Card
                      hoverable
                      cover={
                        <img
                          alt="example"
                          src={`data:image/png;base64,${item.image_url}`}
                          style={{
                            display: "block",
                            width: "auto",
                            height: "180px",
                            margin: "auto",
                            marginTop: "35px",
                          }}
                        />
                      }
                    >
                      <Meta
                        title={
                          <Tooltip title="Click to view post" color={"#17c1e8"}>
                            <p onClick={() => handleOpenInstaLink(item.share_link)}>{item.caption}</p>
                          </Tooltip>
                        }
                      />
                      <div className="d-flex w-100 align-items-center mt-2 justify-content-between">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            {item.comment_count}
                            <i className="fa-solid fa-heart"></i>
                          </div>
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            {item.like_count}
                            <i className="fa-solid fa-comment"></i>
                          </div>
                        </div>
                        {!item.is_minted && (
                          <Button
                            disabled={isLoading.loading}
                            loading={isLoading.loading && isLoading.media_item_id === item.media_item_id}
                            onClick={() => handleClickMintPost(item)}
                          >
                            Mint
                          </Button>
                        )}
                      </div>
                    </Card>
                  </Badge.Ribbon>
                ))}
              </div>
            ) : (
              <>
                <h1 className="w-100 d-flex items-center justify-content-center">No posts available</h1>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InstagramPosts;
