import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import "../../style/admin.css";
import ProjectWlogo from "../../Utils/Images/ProjectWlogo.svg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SetEncryptAccessTokenformat, SetEncryptEmailformat, SetEncryptReferenceNumberformat, SetEncryptUniqId, SetUserData } from "../Common/Common";

const QRAuth = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [status, setStatus] = useState("pending");
  const [loginData, setLoginData] = useState(null);
  const [browserInfo, setBrowserInfo] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const socket = io("https://api.projectw.ai", {
      rejectUnauthorized: false,
    });

    socket.on("connect", () => {
      socket.on("recieveQR", (data) => {
        setQrCodeUrl(data.qrCodeUrl);

        setStatus(data.status);
      });

      socket.on("getLoginData", (data) => {
        setLoginData(data);
        setStatus(data.status);
      });

      socket.on("error", (data) => {
        console.error("Error received1111:", data);
      });

      socket.on("getLinkedDevice", (data) => {});

      const device_details = browserInfo;

      socket.emit("generate-qr", { device_details });
    });

    return () => {
      socket.disconnect();
    };
  }, [browserInfo]);

  useEffect(() => {
    const getBrowserDetails = () => {
      const userAgent = navigator.userAgent;
      const browserName = userAgent.includes("Chrome") ? "Chrome" : userAgent.includes("Firefox") ? "Firefox" : userAgent.includes("Safari") ? "Safari" : "Unknown";

      setBrowserInfo({
        browserName,
        userAgent,
        platform: navigator.platform,
      });
    };

    getBrowserDetails();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setBrowserInfo((prevInfo) => ({
          ...prevInfo,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }));
      });
    }
  }, []);

  const HandleNav = () => {
    const token = localStorage.getItem("access_token");
    navigate(token ? "/dashboard" : "/");
  };

  if (status === "activated" && loginData) {
    SetEncryptEmailformat(loginData?.email);
    SetEncryptReferenceNumberformat(loginData?.referenceNumber);
    SetEncryptAccessTokenformat(loginData?.accessToken);
    SetUserData(loginData?.userName);
    SetEncryptUniqId(loginData?.uniqId);
    navigate("/dashboard");
  }

  return (
    <>
      <div className="faq_css">
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => HandleNav()}
          style={{
            fontSize: "15px",
            position: "absolute",
            top: "16px",
            left: "16px",
            zIndex: "99999",
          }}
        >
          Go Back
        </Button>
        <div className="qr-web-container">
          <header className="qr-header">
            <img src={ProjectWlogo} alt="WhatsApp Logo" className="logoqr" />
            <h1>Log into ProjectW Web</h1>
          </header>

          <div className="main-content">
            <div className="instructions">
              <ol>
                <li>Open ProjectW App on your phone.</li>
                <li>
                  Tap <strong>Menu</strong> <span className="dots">⋮</span> on Android, or <strong>Settings</strong> ⚙️ on iPhone.
                </li>
                <li>
                  Tap <strong>Linked devices</strong> and then <strong>Link a device</strong>.
                </li>
                <li>Point your phone at this screen to scan the QR code.</li>
              </ol>
            </div>

            <div className="qr-code-container">
              {qrCodeUrl ? (
                <img
                  alt="example"
                  src={qrCodeUrl}
                  style={{
                    display: "block",
                    width: "auto",
                    height: "180px",
                    margin: "auto",
                  }}
                />
              ) : (
                <p>Generating QR Code...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRAuth;
