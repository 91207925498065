import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Tooltip,  Card, Tag, Select } from "antd";
import {
  adminDashboardCost,
  AdminRegisterUersDetails,
  GeminiBillingCost,
  GetAllUserDetails,
  GetGeminiLimit,
  GetOpenAiLimit,
  getServerCostGraph,
  GetServerDailyCost,
  GetUserLimit,
  getUserStabilityDiffusionLimitSet,
  OpenAPIBillingCost,
  StabilityDiffusionCost,
} from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { DecryptedDataAdminlocalStorage } from "../../Components/Common/Common";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip as ChartTooltip, Legend, PointElement } from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, ChartTooltip, Legend, PointElement);
const { Option } = Select;

const Dashboard = () => {
  const {
    userlimitdata,
    OpenAiLimit,
    StabilityLimit,
    GeminiLimit,
    ServerDailyCost,
    BillingCostOpenAI,
    StabilityDiffusion,
    BillingCostGemini,
    CostGraph,
    DashboardCardCost,
    AllUserDetails,
    DashboardGraph,
  } = useSelector((state) => state.counter);

 

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [carddate, setCardDate] = useState("");
  const [AllUserDetailsData, setAllUserDetails] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [AwsCostValue, setAwsCostValue] = useState("");



  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Add Users",
        data: [],
        fill: false,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  });

  let body = {
    user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
    changeyear: selectedYear,
  };
  useEffect(() => {
    if (AllUserDetails) {
      setAllUserDetails(AllUserDetails);
    }
  }, [AllUserDetails]);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (currentYear) {
      const fetchData = () => {
        dispatch(getServerCostGraph({ currentYear }));
      };
      fetchData();
    }
  }, [dispatch, currentYear]);

  useEffect(() => {
    dispatch(GetAllUserDetails({ dispatch }));
  }, []);

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const activeUsersCount = AllUserDetailsData?.filter((user) => user.is_online === 1)?.length;

  useEffect(() => {
    if (selectedYear) {
      dispatch(AdminRegisterUersDetails({ dispatch, body }));
    }
  }, [selectedYear]);

  const HandleYearChange = (value) => {
    setSelectedYear(value);
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    setCardDate(currentDate);
  }, []);

  useEffect(() => {
    dispatch(OpenAPIBillingCost({ dispatch }));
    dispatch(GetServerDailyCost({ dispatch }));
    dispatch(GeminiBillingCost({ dispatch }));
    dispatch(StabilityDiffusionCost({ dispatch }));
    dispatch(GetUserLimit({ dispatch, body }));
    dispatch(GetOpenAiLimit({ dispatch, body }));
    dispatch(getUserStabilityDiffusionLimitSet({ dispatch, body }));
    dispatch(GetGeminiLimit({ dispatch, body }));
    dispatch(AdminRegisterUersDetails({ dispatch, body }));
    const initialYears = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() + i);
    setYears(initialYears);
  }, [dispatch]);

  useEffect(() => {
    if (DashboardGraph && DashboardGraph?.length) {
      const labels = DashboardGraph?.map((month) => month?.month);
      const data = DashboardGraph?.map((month) => month?.user_count);
      setChartData({
        labels,
        datasets: [
          {
            label: "Monthly Register Users",
            data,
            fill: false,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
          },
        ],
      });
    }
  }, [DashboardGraph]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Register Users",
      },
    },
  };

  const awsCost = CostGraph?.data || 0;
  const awsUnit = ServerDailyCost?.Unit || "USD";

  const openAIAmount = BillingCostOpenAI?.total_cost || 0;
  const openAIUnit = BillingCostOpenAI?.Unit || "USD";

  const stabilityDiffusionCost = StabilityDiffusion?.total_cost_in_dollar || 0;
  const stabilityDiffusionUnit = StabilityDiffusion?.Unit || "USD";

  const geminiData = BillingCostGemini?.total_cost || 0;
  const geminiUnit = BillingCostGemini?.Unit || "USD";

  useEffect(() => {
    if (carddate) {
      dispatch(adminDashboardCost({ dispatch, carddate }));
    }
  }, [, carddate]);

  useEffect(() => {
    function getCurrentMonthData(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      const currentMonthIndex = new Date().getMonth();
      const currentMonth = monthNames[currentMonthIndex];

      return data ? data[currentMonth] : null;
    }

    if (awsCost) {
      const currentMonthData = getCurrentMonthData(awsCost);

      setAwsCostValue(currentMonthData);
    }
  }, [AwsCostValue, awsCost]);

  const costData = [
    {
      title: "AWS",
      cost: `${AwsCostValue} ${awsUnit}`,
      amount: `${userlimitdata}$`,
      tooltip: "Click to view AWS data",
      dailycost: DashboardCardCost && DashboardCardCost.length > 0 ? DashboardCardCost[3].total_cost : null,
    },
    {
      title: "Open AI",
      cost: `${openAIAmount} ${openAIUnit}`,
      amount: `${OpenAiLimit}$`,
      tooltip: "Click to view Open AI data",
      dailycost: DashboardCardCost && DashboardCardCost.length > 0 ? DashboardCardCost[0].total_cost : null,
    },
    {
      title: "Stability Diffusion",
      cost: `${stabilityDiffusionCost} ${stabilityDiffusionUnit}`,
      amount: `${StabilityLimit}$`,
      tooltip: "Click to view Stability Diffusion data",
      dailycost: DashboardCardCost && DashboardCardCost.length > 0 ? DashboardCardCost[1].total_cost : null,
    },
    {
      title: "Gemini AI",
      cost: `${geminiData} ${geminiUnit}`,
      amount: `${GeminiLimit}$`,
      tooltip: "Click to view Gemini AI data",
      dailycost: DashboardCardCost && DashboardCardCost.length > 0 ? DashboardCardCost[2].total_cost : null,
    },
    {
      title: "Hugging Face",
      cost: "20$ USD",
      amount: "9$",
      tooltip: "Click to view Hugging Face data",
      dailycost: 0,
    },
    {
      title: "Flux",
      cost: "0$ USD",
      amount: "0$",
      tooltip: "Click to view flux data",
      dailycost: 0,
    },
    {
      title: "Llama 3.2",
      cost: "0$ USD",
      amount: "0$",
      tooltip: "Click to view Llama 3.2 data",
      dailycost: 0,
    },
  ];

  const onCardClick = (item) => {
    navigate("/billingstatistics");
  };

  return (
    <>
      <div className="billing-container">
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <h1 style={{ fontWeight: "bold", fontSize: "24px", color: "#17c1e8", textAlign: "center" }}>Admin Dashboard</h1>
          <p className="title" style={{ fontWeight: 600 }}>
            <span>No of users</span> <Tag color="green">● Active</Tag> : {activeUsersCount}
          </p>
        </div>

        <Row justify="center" style={{ justifyContent: "space-between" }}>
          {costData?.map((item, index) => (
            <Col xs={24} sm={12} md={24} lg={4} xl={7} key={index}>
              <Tooltip title={item.tooltip}>
                <Card
                  className="dashboardCard"
                  style={{
                    background: "linear-gradient(to bottom, #c3e6ec, #a7d1d9)",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    cursor: "pointer",
                    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
                    transition: "box-shadow 0.3s ease-in-out",
                    border: "none",
                  }}
                  onClick={() => onCardClick(item)}
                >
                  <div className="go-corner">
                    <span className="go-arrow" style={{ marginTop: "-4px", marginRight: "-4px", color: "white", fontFamily: "courier, sans" }}>
                      →
                    </span>
                  </div>
                  <h4 className="dashboardCard-title dashboardCard-line" style={{ textAlign: "center" }}>
                    {item.title}
                  </h4>
                  <hr />
                  <div className="dashboardCard-desc">
                    <p>
                      Your threshold amount is: <span style={{ fontWeight: "bold" }}>{item.amount}</span>
                    </p>
                    <p>
                      Cost: <span style={{ fontWeight: "bold" }}>{item.cost}</span>
                    </p>
                    <p className="">
                      Today Cost: <span style={{ fontWeight: "bold" }}>{item.dailycost}</span>
                    </p>
                  </div>
                </Card>
              </Tooltip>
            </Col>
          ))}
        </Row>

        <div style={{ display: "flex", justifyContent: "end", margin: "40px 30px 0 0" }}>
          <Select
            defaultValue={selectedYear}
            onChange={HandleYearChange}
            style={{
              width: 120,
            }}
          >
            {years?.map((year) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </div>
        <div className="graphCss">
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
