import React, { useEffect, useState } from "react";
import { Card, Row, Col, Input, Table, Tooltip, Modal, Badge, DatePicker, Select, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUserDetails } from "../../redux/auth/authSlice";

const { RangePicker } = DatePicker;
const { Option } = Select;

const UserDetails = () => {
  const dispatch = useDispatch();
  const { AllUserDetails } = useSelector((state) => state?.counter);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AllUserDetailsData, setAllUserDetails] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [optiondata, setOptionData] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    if (AllUserDetails) {
      setAllUserDetails(AllUserDetails);
      setFilteredData(AllUserDetails);
    }
  }, [AllUserDetails]);

  const showUserDetails = (record) => {
    setSelectedUser(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  useEffect(() => {
    dispatch(GetAllUserDetails({ dispatch }));
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
    filterData(value, dateRange);
  };

  const filterData = (searchValue, dateRange, dateStr) => {
    let data = [...AllUserDetailsData];

    if (searchValue) {
      data = data.filter((user) => Object.values(user).join(" ").toLowerCase().includes(searchValue.toLowerCase()));
    }

    if (dateStr && dateStr[0] && dateStr[1]) {
      const [start, end] = dateStr;
      const startDate = new Date(start);
      const endDate = new Date(end);

      data = data.filter((item) => {
        const createdAt = new Date(item.created_at);
        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    setFilteredData(data);
    setPagination({ ...pagination, current: 1 });
  };

  const handleDateChange = (dates, dateStr) => {
    setDateRange(dates);
    filterData(searchText, dates, dateStr);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return headers + rows;
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadCSV = () => {
    const data = filteredData.map((item) => {
      const { password, access_token, token_expiry, token_id, hashed_token_id, ...rest } = item;
      return { ...rest };
    });

    const csv = convertToCSV(data);
    downloadCSV(csv);
  };

  const HandleChangeOption = (value) => {
    setOptionData(value);

    let filtered = AllUserDetailsData;

    if (value === "Active") {
      filtered = AllUserDetailsData.filter((user) => user.is_online === 1);
    } else if (value === "Inactive") {
      filtered = AllUserDetailsData.filter((user) => user.is_online === 0);
    } else if (value === "All") {
      filtered = AllUserDetailsData;
    }

    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "_id",
      key: "_id",
      render: (_, __, index) => (
        <span>
          <p>{(pagination.current - 1) * pagination.pageSize + index + 1}</p>
        </span>
      ),
    },
    {
      title: "UserName",
      dataIndex: "username",
      key: "username",
      render: (username) => (
        <span>
          <p>{username ? username : "N/A"}</p>
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <span>
          <p>{email ? email : "N/A"}</p>
        </span>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => (
        <span>
          <p>{phone ? phone : "N/A"}</p>
        </span>
      ),
    },
    {
      title: "Created_at",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Status",
      dataIndex: "is_online",
      key: "is_online",
      render: (is_online) => (
        <span>
          <Badge status={is_online === 1 ? "success" : "error"} text={is_online ? "Active" : "Inactive"} />
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <Tooltip title="View Details">
          <EyeOutlined style={{ cursor: "pointer", color: "#1890ff", textAlign: "center" }} onClick={() => showUserDetails(record)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <div className="billing-container">
        <h1 style={{ textAlign: "center", fontWeight: "bold", fontSize: "24px", marginBottom: "16px", color: "#17c1e8" }}>User Details</h1>

        <Row style={{ marginTop: "20px" }} justify="center">
          <Col xs={24} sm={18} md={12}>
            <Input.Search placeholder="Search" size="large" value={searchText} onChange={(e) => handleSearch(e.target.value)} style={{ width: "100%" }} />
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }} justify="center">
          <Col xs={24} sm={18} md={12}>
            <RangePicker onChange={handleDateChange} format="YYYY-MM-DD" value={dateRange} style={{ width: "100%" }} />
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }} justify="center">
          <Col xs={24}>
            <div style={{ overflowX: "auto" }}>
              <div>
                <p style={{ fontWeight: "600" }}>No of Active Users: {AllUserDetailsData.filter((user) => user.is_online === 1).length}</p>
              </div>
              <div>
                <p style={{ fontWeight: "600" }}>No of Total Users: {AllUserDetailsData?.length ? AllUserDetailsData?.length : 0}</p>
              </div>

              <Select defaultValue={optiondata} onChange={HandleChangeOption} style={{ width: 120, marginLeft: "16px" }}>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
                <Option value="All">All</Option>
              </Select>

              {optiondata !== "All" && (
                <Button onClick={handleDownloadCSV} style={{ marginLeft: "16px" }}>
                  Download CSV
                </Button>
              )}

              <Table
                dataSource={searchText || dateRange?.length > 0 ? filteredData : AllUserDetailsData}
                columns={columns}
                pagination={{
                  ...pagination,
                  total: (searchText || dateRange?.length > 0 ? filteredData : AllUserDetailsData).length,
                }}
                onChange={handleTableChange}
                scroll={{ x: "max-content" }}
              />
            </div>
          </Col>
        </Row>
      </div>

      <Modal title="User Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        {selectedUser && (
          <Card>
            <p>
              <strong>UserName:</strong> {selectedUser.username ? selectedUser.username : "N/A"}
            </p>
            <p>
              <strong>Email:</strong> {selectedUser.email ? selectedUser.email : "N/A"}
            </p>
            <p>
              <strong>Phone:</strong> {selectedUser.phone ? selectedUser.phone : "N/A"}
            </p>
            <p>
              <strong>City:</strong> {selectedUser.city ? selectedUser.city : "N/A"}
            </p>
            <p>
              <strong>Country:</strong> {selectedUser.country ? selectedUser.country : "N/A"}
            </p>
            <p>
              <strong>Reference Number:</strong> {selectedUser.reference_number ? selectedUser.reference_number : "N/A"}
            </p>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default UserDetails;
