import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpenAIandGeminiUserDataSave, ReGenerateToken, UploadToBucket } from "../../redux/auth/authSlice";
import Comment from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import { Flex, Tooltip, Select, Upload, Button } from "antd";
import Format from "../../Utils/helpers/Format";
import { CopyOutlined, CheckOutlined, UploadOutlined } from "@ant-design/icons";
import ProjectWlogo from "../../../src/Utils/Images/ProjectWlogo.svg";
import { VideoCameraOutlined, PictureOutlined, AudioOutlined } from "@ant-design/icons";

import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";
const ChatAI = () => {
  const { Option } = Select;
  const [isRecording, setIsRecording] = useState(false);
  const [audioBase64, setAudioBase64] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [aiagentinput, setAiagentinput] = useState("");
  const [image, setImage] = useState({
    init_image: "",
    image_prompt: "",
    motion_bucket_id: 10,
    num_frames: 25,
    output_type: "mp4",
  });
  const [convertation, setConvertation] = useState([]);
  const defaultState = {
    text: false,
    image: false,
    audio: false,
    video: false,
    imagevideo: false,
    audiovideo: false,
  };

  const text = "Chat (T - T)";
  const imagetext = "Generate image (T - I)";
  const audiotext = "Voice Chat (V - T)";
  const videotext = "Text to Video (T - V)";
  const Imagetovideotext = "Image to Video (I - V)";
  const Audiotovideotext = "Audio to  Video (A - V)";
  const deleteAllchat = "Clear all Chat";

  const { S3BucketImage } = useSelector((state) => state?.counter);

  const [selecticon, setSelectIcon] = useState({ ...defaultState, text: true });
  const [activeOption, setActiveOption] = useState("text");
  const [audioAnswer, setAudioAnswer] = useState(null);

  const dispatch = useDispatch();

  const answerScroll = useRef(null);

  const HandleChange = (e) => {
    setAiagentinput(e?.target?.value);
  };

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Media Devices not supported");
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      audioChunksRef.current = [];

      const base64String = await convertBlobToBase64(audioBlob);
      setAudioBase64(base64String);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
      setIsRecording(false);
    }
  };

  useEffect(() => {
    if (audioBase64 && audioBase64?.length > 0) {
      if (selecticon?.audiovideo) {
        HandleAudioToVideo();
      } else {
        HandleAudioGeneration(audioBase64);
      }
    }
  }, [audioBase64]);

  useEffect(() => {
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }
  }, [convertation]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const playAudio = (generatedAudio) => {
    const audio = new Audio(`data:audio/mp3;base64,${generatedAudio}`);
    audio.play().then(() => {
      setIsPlaying(true);
    });

    audio.addEventListener("ended", () => {
      setIsPlaying(false);
    });

    audio.play();
  };

  useEffect(() => {
    if (audioAnswer) {
      setConvertation((prevState) => {
        let previousResponse = [...prevState];

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          ...audioAnswer,
        };

        return previousResponse;
      });
    }
  }, [audioAnswer]);

  const AnimatedText = ({ text, hasAudio, audio }) => {
    const [displayText, setDisplayText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (currentIndex < text?.length) {
          setDisplayText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex(function (prevIndex) {
            return prevIndex + 1;
          });
        } else {
          setConvertation((prevState) => {
            let newState = [...prevState];
            newState[newState?.length - 1] = {
              ...newState[newState?.length - 1],
              animate: false,
            };
            return newState;
          });
          clearInterval(interval);
        }
      }, 10);
      return () => clearInterval(interval);
    }, [currentIndex, text]);

    if (hasAudio) {
      return (
        <div className="animated-text">
          {displayText}
          {currentIndex >= text?.length && (
            <span style={{ color: "red" }} onClick={() => playAudio(audio)}>
              Play Again
            </span>
          )}
        </div>
      );
    }

    return <div className="animated-text">{displayText}</div>;
  };

  const WaveLoader = () => {
    return (
      <div className="loading">
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
        <div className="obj"></div>
      </div>
    );
  };

  const Question = ({ hasAudio, userAudio, question }) => {
    if (hasAudio) {
      return (
        <audio src={`data:audio/wav;base64,${userAudio}`} controls>
          Your browser does not support the <code>audio</code> element.
        </audio>
      );
    }
    return question;
  };

  const Answer = ({ answer, animateText, hasImage, hasAudio, hasVideo, audio, status, isVideo }) => {
    const videoRef = useRef(null);
    const [isCopied, setIsCopied] = useState(false);
    const [buttonText, setButtonText] = useState("");

    const handlePlayPause = (audiodsta) => {
      const audio = new Audio(`data:audio/wav;base64,${audiodsta}`);

      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play().then(() => {
          setIsPlaying(true);
        });

        audio.addEventListener("ended", () => {
          setIsPlaying(false);
        });
      }
    };

    useEffect(() => {
      const playVideo = async () => {
        if (hasVideo && videoRef.current) {
          try {
            videoRef.current.load();
            await videoRef.current.play();
          } catch (error) {
            console.error("Error playing video:", error);
          }
        }
      };

      playVideo();
    }, [answer, hasVideo]);

    if (isVideo) {
      return <WaveLoader />;
    }

    if (!status) {
      return (
        <div className="chat-answer">
          <Comment height="50" width="50" color="#4b49ac" visible={true} wrapperStyle={{}} backgroundColor="#F4442E" ariaLabel="comment-loading" wrapperClass="comment-wrapper" />
        </div>
      );
    }
    if (!answer && (hasAudio || hasVideo)) {
      return <WaveLoader />;
    }

    if (hasAudio && hasVideo) {
      return (
        <video width="400" controls ref={videoRef} className="video_resp_css">
          <source src={answer} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      );
    }

    if (hasAudio) {
      // const playAudio = (audio) => {
      //   const audioElement = new Audio(audio);
      //   audioElement.play();
      // };

      return (
        <div style={{ color: "black" }}>
          {answer}
          {audio && (
            <div>
              <i onClick={() => handlePlayPause(audio)} style={{ cursor: "pointer" }} className={isPlaying ? "fa-regular fa-circle-pause" : "fa-regular fa-circle-play"}></i>
            </div>
          )}
        </div>
      );
    }

    if (hasVideo && answer && status) {
      return (
        <video width="400" controls ref={videoRef} className="video_resp_css">
          <source src={answer} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      );
    }

    if (hasImage) {
      return <img height={700} src={`data:image/png;base64,${answer}`} alt="Answer" />;
    }

    if (animateText) {
      return (
        <div className="chat-answer">
          <AnimatedText text={answer} />
        </div>
      );
    }

    if (answer && answer?.length > 0) {
      const copyToClipboard = () => {
        navigator.clipboard
          .writeText(answer)
          .then(() => {
            setButtonText("Copied");
            setIsCopied(true);
            setTimeout(() => {
              setButtonText("");
              setIsCopied(false);
            }, 2000);
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      };

      return (
        <div className="chat-wrapper">
          <button onClick={copyToClipboard} className="copy-button">
            {isCopied ? <CheckOutlined /> : <CopyOutlined />}
            {buttonText}
          </button>
          <div className="chat-container">
            <div className="chat-answer">
              <Format answer={answer} isShare={false} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="chat-answer">
        <Comment height="50" width="50" color="#4b49ac" visible={true} wrapperStyle={{}} backgroundColor="#F4442E" ariaLabel="comment-loading" wrapperClass="comment-wrapper" />
      </div>
    );
  };

  const HandleFileChange = (fileList) => {
    if (!fileList || !fileList.length || !fileList[0]?.originFileObj) {
      console.error("Invalid file list or file object");
      return;
    }

    const fileObject = fileList[0].originFileObj;

    const obj = {
      file: fileObject,
      file_name: fileObject.name,
      file_type: fileObject.type,
    };

    if (obj.file && obj.file_name && obj.file_type) {
      dispatch(UploadToBucket({ obj }));
    } else {
      console.error("File details are missing");
    }
  };

  const HandleClearChat = (e) => {
    e?.preventDefault();
    toast.success("Chat Cleared");
    setConvertation(
      convertation.filter((_) => {
        return;
      })
    );
  };

  // ********************APIS***********************************************

  const HandleAiagent = (e) => {
    e?.preventDefault();
    setAiagentinput("");
    let previousResponse = [...convertation];

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: false,
    });
    setConvertation(previousResponse);
    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    async function query() {
      try {
        let form_data = new FormData();
        form_data.append("text", aiagentinput);
        form_data.append("email", email);
        form_data.append("reference_number", reference_number);

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/ai_agent_input`, {
          method: "POST",
          body: form_data,
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.ai_response?.response_text,
          };

          setConvertation(previousResponse);
          return;
        }

        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
          };
          setConvertation(updatedResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.ai_response?.response_text,
          animate: true,
          showStatus: true,
        };

        setConvertation(previousResponse);
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }

        let numberdata = result.total_cost;

        if (result) {
          let obj = {
            email: email,
            reference_number: reference_number,
            total_cost: parseFloat(numberdata),
            service: "OPENAI",
          };
          dispatch(OpenAIandGeminiUserDataSave({ obj, dispatch }));
        }
      } catch (error) {
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate video from audio.",
        };
        setConvertation(updatedResponse);
        toast.error(error);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const HandleAudioGeneration = async (audiobase64) => {
    let previousResponse = [...convertation];

    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    let audioobj = {
      audio: audiobase64,
      email: email,
      reference_number: reference_number,
    };

    previousResponse.push({
      answer: "",
      hasAudio: true,
      userAudio: audiobase64,
    });

    setConvertation(previousResponse);

    try {
      const responses = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/voice_chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(audioobj),
      });

      const result = await responses.json();

      if (result?.error) {
        const answerResp = {
          answer: result?.error,
          audio: null,
        };

        setAudioAnswer(answerResp);
        return;
      }

      const answerResp = {
        answer: result?.ai_response,
        audio: result.audio,
        showStatus: true,
      };

      setAudioAnswer(answerResp);

      if (result?.status === 200) {
        playAudio(result.audio);
      }

      if (answerScroll.current) {
        answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
      }
    } catch (error) {
      const answerResp = {
        answer: "Something went wrong please try again!",
        audio: null,
      };
      const updatedResponse = [...previousResponse];
      updatedResponse[updatedResponse.length - 1] = {
        ...updatedResponse[updatedResponse.length - 1],
        answer: "Failed to generate video from audio.",
      };
      setConvertation(updatedResponse);
      setAudioAnswer(answerResp);
      toast.error(error);
    }
  };

  const HandleAiImageagent = (e) => {
    e?.preventDefault();
    setAiagentinput("");
    let previousResponse = [...convertation];

    previousResponse[previousResponse?.length - 1] = {
      ...previousResponse[previousResponse?.length - 1],
      animate: false,
    };

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });
    setConvertation(previousResponse);
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }

    const email = GetDecryptEmailformat();

    const reference_number = GetDecryptReferenceNumberformat();

    async function query() {
      try {
        let form_data = new FormData();
        form_data.append("prompt", aiagentinput);
        form_data.append("email", email);
        form_data.append("reference_number", reference_number);

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/image_generation`, {
          method: "POST",
          body: form_data,
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.error,
          };

          setConvertation(previousResponse);
          return;
        }

        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
          };
          setConvertation(updatedResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.image_base64,
          hasImage: true,
          showStatus: true,
        };

        setConvertation(previousResponse);

        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error(error);
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate video from audio.",
        };
        setConvertation(updatedResponse);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const HandleVideoAiagent = (e) => {
    e?.preventDefault();
    setAiagentinput("");
    let previousResponse = [...convertation];

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });

    setConvertation(previousResponse);
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }

    async function query() {
      try {
        let form_data = new FormData();
        form_data.append("prompt", aiagentinput);

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/modellab_video`, {
          method: "POST",
          body: form_data,
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.ai_response?.response_text,
          };

          setConvertation(previousResponse);
          return;
        }
        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
          };
          setConvertation(updatedResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.future_links ? result?.future_links[0] : result?.output[0],
          hasVideo: true,
          animate: true,
        };

        let dataurl = result?.future_links ? result?.future_links[0] : result?.output[0];

        setConvertation(previousResponse);

        pollForVideoAvailability(dataurl, previousResponse);
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error(error);
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate video from audio.",
        };
        setConvertation(updatedResponse);
      }
    }

    query().then((response) => {
      return response;
    });
  };
  const HandleImageToVideo = (e) => {
    e?.preventDefault();
    setAiagentinput("");

    let previousResponse = [...convertation];
    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });
    setConvertation(previousResponse);

    async function query() {
      try {
        const obj = {
          init_image: S3BucketImage ? S3BucketImage : "",
          image_prompt: aiagentinput,
          motion_bucket_id: 10,
          num_frames: 25,
          output_type: "mp4",
        };

        const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/mimage_video`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        });

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.ai_response?.response_text,
          };
          setConvertation(previousResponse);
          return;
        }

        if (result?.message === "Rate limit exceeded") {
          const updatedResponse = [...previousResponse];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: result.tips,
          };
          setConvertation(updatedResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.future_links ? result?.future_links[0] : result?.output[0],
          hasVideo: true,
        };

        setConvertation(previousResponse);

        let dataurl = result?.future_links ? result?.future_links[0] : result?.output[0];

        pollForVideoAvailability(dataurl, previousResponse);
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error("An error occurred: " + error.message);
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate video from audio.",
        };
        setConvertation(updatedResponse);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const HandleAudioToVideo = async () => {
    let previousResponse = [...convertation];

    previousResponse.push({
      answer: "",
      hasAudio: true,
      userAudio: audioBase64,
    });

    setConvertation(previousResponse);

    try {
      const response = await fetch(`${process.env.REACT_APP_apiBaseUrl}/ai/api/voice_video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ audio: audioBase64 }),
      });

      const result = await response.json();

      if (result?.error) {
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: result.error,
        };
        setConvertation(updatedResponse);
        return;
      }

      if (result?.message === "Rate limit exceeded") {
        const updatedResponse = [...previousResponse];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: result.tips,
        };
        setConvertation(updatedResponse);
        return;
      }

      previousResponse[previousResponse?.length - 1] = {
        ...previousResponse[previousResponse?.length - 1],
        answer: result?.future_links ? result?.future_links[0] : result?.output[0],
        hasVideo: true,
        hasAudio: true,
        animate: true,
        isVideo: true,
      };

      let dataurl = result?.future_links ? result?.future_links[0] : result?.output[0];

      setConvertation(previousResponse);

      pollForVideoAvailability(dataurl, previousResponse);

      if (answerScroll.current) {
        answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
      const updatedResponse = [...previousResponse];
      updatedResponse[updatedResponse.length - 1] = {
        ...updatedResponse[updatedResponse.length - 1],
        answer: "Failed to generate video from audio.",
      };
      setConvertation(updatedResponse);
    }
  };

  const pollForVideoAvailability = async (url, prevres) => {
    const maxRetries = 40; // Maximum number of polling attempts
    const delay = 1000; // 3 seconds between each poll
    let attempt = 0;

    while (attempt < maxRetries) {
      try {
        const response = await fetch(url, { method: "HEAD" }); // Check if the video exists
        if (response.ok) {
          const updatedResponse = [...prevres];
          updatedResponse[updatedResponse.length - 1] = {
            ...updatedResponse[updatedResponse.length - 1],
            answer: url,
            showStatus: true,
            isVideo: false,
          };
          setConvertation(updatedResponse);

          return;
        }
      } catch (error) {
        console.error("Error checking video availability:", error);
        const updatedResponse = [...prevres];
        updatedResponse[updatedResponse.length - 1] = {
          ...updatedResponse[updatedResponse.length - 1],
          answer: "Failed to generate video.",
        };
        setConvertation(updatedResponse);
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
      attempt++;
    }

    console.error("Video did not become available within the expected time");
  };

  return (
    <>
      <div className="Chat_bot_cssAiagent ">
        <div className="d-flex justify-content-center">
          <h1 style={{ fontSize: "16px" }}>ProjectW(AI)</h1>
        </div>
        <div className="main-div-msg">
          <div className="input-box">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-lg-5">
                <div className="button-group">
                  <div className="first-btn">
                    <ul className="first-button">
                      <div className="dropdown my-3">
                        <Select
                          style={{ width: 250 }}
                          placeholder="Chat"
                          value={activeOption}
                          onChange={(value) => {
                            setActiveOption(value);
                            switch (value) {
                              case "video":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  video: true,
                                }));
                                break;
                              case "imagevideo":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  imagevideo: true,
                                }));
                                break;
                              case "audiovideo":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  audiovideo: true,
                                }));
                                break;
                              case "text":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  text: true,
                                }));
                                break;
                              case "image":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  image: true,
                                }));
                                break;
                              case "audio":
                                setSelectIcon(() => ({
                                  ...defaultState,
                                  audio: true,
                                }));
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          <Option value="text" className={activeOption === "text" ? "active-option" : ""}>
                            <VideoCameraOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "text" ? "#1890ff" : "inherit",
                              }}
                            />
                            {text || "Chat"}
                          </Option>
                          <Option value="image" className={activeOption === "image" ? "active-option" : ""}>
                            <VideoCameraOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "image" ? "#1890ff" : "inherit",
                              }}
                            />
                            {imagetext || "Image"}
                          </Option>
                          <Option value="audio" className={activeOption === "audio" ? "active-option" : ""}>
                            <VideoCameraOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "audio" ? "#1890ff" : "inherit",
                              }}
                            />
                            {audiotext || "Audio"}
                          </Option>
                          <Option value="video" className={activeOption === "video" ? "active-option" : ""}>
                            <VideoCameraOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "video" ? "#1890ff" : "inherit",
                              }}
                            />
                            {videotext || "Text to Video"}
                          </Option>
                          <Option value="imagevideo" className={activeOption === "imagevideo" ? "active-option" : ""}>
                            <PictureOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "imagevideo" ? "#1890ff" : "inherit",
                              }}
                            />
                            {Imagetovideotext || "Image to Video"}
                          </Option>
                          <Option value="audiovideo" className={activeOption === "audiovideo" ? "active-option" : ""}>
                            <AudioOutlined
                              style={{
                                marginRight: 8,
                                color: activeOption === "audiovideo" ? "#1890ff" : "inherit",
                              }}
                            />
                            {Audiotovideotext || "Audio to Video"}
                          </Option>
                        </Select>
                      </div>
                    </ul>

                    <div className="mx-3 icon_resp_css">
                      <Flex vertical justify="center" align="center" className="demo">
                        <Flex
                          justify="center"
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip placement="bottom" title={deleteAllchat}>
                            <div>
                              <button className="btn btn-link" onClick={(e) => HandleClearChat(e)} disabled={convertation == ""}>
                                <i className={`fa-solid fa-trash-can    ${convertation?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                              </button>
                            </div>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                {selecticon?.text ? (
                  <div className="input-group" style={{ alignItems: "center", width: "100%" }}>
                    <span className="input-group-text">
                      <i className="fa-regular fa-message"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control input_css_for_all_placeholder"
                      name="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && aiagentinput?.length > 0) {
                          HandleAiagent();
                        }
                      }}
                      onChange={(e) => HandleChange(e)}
                      value={aiagentinput}
                      placeholder="Ask a question..."
                    />
                    <button className="btn btn-link" onClick={(e) => HandleAiagent(e)} disabled={aiagentinput?.length == 0}>
                      <i className={`fa-regular fa-paper-plane ${aiagentinput?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                    </button>
                  </div>
                ) : selecticon?.audio ? (
                  <div className="d-flex justify-content-center recording_css">
                    <button onClick={isRecording ? stopRecording : startRecording}>
                      <i
                        style={{
                          color: isRecording ? "red" : "gray",
                          cursor: "pointer",
                        }}
                        className="fa-solid fa-microphone "
                      >
                        <span className="mx-2"> {isRecording ? "Stop Voice Chat" : "Start Voice Chat"}</span>
                      </i>
                    </button>
                  </div>
                ) : selecticon?.image ? (
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa-solid fa-image"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control input_css_for_all_placeholder"
                      name="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && aiagentinput?.length > 0) {
                          HandleAiImageagent();
                        }
                      }}
                      onChange={(e) => HandleChange(e)}
                      value={aiagentinput}
                      placeholder="Generate an image..."
                    />
                    <button className="btn btn-link" onClick={(e) => HandleAiImageagent(e)} disabled={aiagentinput?.length == 0}>
                      <i className={`fa-regular fa-paper-plane  ${aiagentinput?.length > 0 ? "chatai_icon_css" : ""} `}></i>
                    </button>
                  </div>
                ) : selecticon?.imagevideo ? (
                  <div className="input-group d-flex flex-column align-items-stretch">
                    <div className="mb-2 d-flex">
                      <Upload
                        name="image"
                        className="Upload_image_css"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={() => false}
                        onChange={({ fileList }) => HandleFileChange(fileList)}
                      >
                        <Button className="upload_btn_css" icon={<UploadOutlined />}>
                          Upload Image
                        </Button>
                      </Upload>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <span className="input-group-text">
                        <i className="fa-regular fa-message"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control input_css_for_all_placeholder"
                        name="text"
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && aiagentinput?.length > 0) {
                            HandleImageToVideo();
                          }
                        }}
                        onChange={(e) => HandleChange(e)}
                        value={aiagentinput}
                        placeholder="Generate Image to video..."
                      />
                      <button className="btn btn-link" onClick={(e) => HandleImageToVideo(e)} disabled={!image.init_image || aiagentinput.length === 0}>
                        <i className={`fa-regular fa-paper-plane ${aiagentinput.length > 0 ? "chatai_icon_css" : ""}`}></i>
                      </button>
                    </div>
                  </div>
                ) : selecticon?.audiovideo ? (
                  <div className="d-flex justify-content-center recording_css">
                    <button onClick={isRecording ? stopRecording : startRecording}>
                      <i
                        style={{
                          color: isRecording ? "red" : "gray",
                          cursor: "pointer",
                        }}
                        className="fa-solid fa-microphone "
                      >
                        <span className="mx-2"> {isRecording ? "Stop Voice Chat" : "Audio to Video"}</span>
                      </i>
                    </button>
                  </div>
                ) : (
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa-solid fa-video"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control input_css_for_all_placeholder"
                      name="text"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && aiagentinput?.length > 0) {
                          HandleVideoAiagent(e);
                        }
                      }}
                      onChange={(e) => HandleChange(e)}
                      value={aiagentinput}
                      placeholder="Generate an video..."
                    />
                    <button className="btn btn-link" onClick={(e) => HandleVideoAiagent(e)} disabled={aiagentinput?.length == 0}>
                      <i className={`fa-regular fa-paper-plane   ${aiagentinput?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="message_cssAiagent" ref={answerScroll}>
          {convertation.map((item, index) => {
            return (
              <div className="wid-flx" key={index} style={{ width: "100%", maxWidth: "98%" }}>
                <div className="right-ms">
                  <div className="Question_cssAiagent">
                    <p className="mx-3 my-2" style={{ color: "black" }}>
                      <Question hasAudio={item?.hasAudio} userAudio={item?.userAudio} question={item?.question} />
                    </p>
                  </div>
                </div>
                <div className="left-ms">
                  <div className="Answer_cssAiagent my-4">
                    <img className="AI-image" src={ProjectWlogo} alt="" />
                    <Answer
                      answer={item?.answer}
                      audio={item?.audio}
                      hasAudio={item?.hasAudio}
                      animateText={item?.animate}
                      hasImage={item?.hasImage}
                      hasVideo={item?.hasVideo}
                      status={item?.showStatus}
                      isVideo={item?.isVideo}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChatAI;
